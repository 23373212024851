

export const LANG_ES_TRANS = {
    'English': 'Inglés',
    'Spanish': 'Español',
    'German': 'Alemán',
    'French': 'Francés',
    'Italian': 'Italiano',
    'Portuguese': 'Portugués',
    'Your token is not valid. Please try to login again' : 'Su token no es válido. Por favor ingrese al sistema nuevamente',
    'ARTICLES_BTN' : 'Documentos',
    'FORMATS_BTN' : 'Comandos',
    'NOTES_BTN' : 'Notas',
    'BOOKMARKS_BTN' : 'Favoritos',
    'Search' : 'Buscar',
    'What do you want to search?' : '¿Qué quieres buscar?',
    'Searching' : 'Buscando',
    'Article Search' : 'Busqueda de Articulos',
    'Format Search' : 'Búsqueda de comandos',
    'Description': 'Descripción',
    'Tags': 'Etiquetas',
    'STARTS_WITH_BTN' : 'Empieza con',
    'ENDS_WITH_BTN' : 'Finaliza con',
    'CONTAINS_BTN' : 'Contiene',
    "ADD_STAR_TIP" : "Agregar a Favoritos",
    "ADD_NOTE_TIP" : "Agregar una Nota",
    "View More" : "Ver Más",
    "View Less" : "Ver Menos",
    'Formats' : 'Comandos',
    'Commands' : 'Comandos',
    'Errors' : 'Errores',
    'Favorites' : 'Favoritos',
    'Loading': 'Cargando',
    'Use all CAPITALS for commands (or part of the command), and lowercase for descriptions' : 'Usar MAYÚSCULAS para comandos (o parte del comando) y minúsculas para las descripciones',
    'Availability' : 'Disponibilidad',
    'Paste a copy of the system error and/or error number to search' : 'Pegar una copia del error del sistema y/o el número de error a buscar',
    'Search Examples:' : 'Ejemplos de búsqueda:',
    'COMMAND + description:' : 'COMANDO + descripción:',
    ' WP with air extras' : ' WP con air extras',
    'Partial command:' : 'Comando parcial:',
    ' pricing with air extras' : ' cotización con air extras',
    'Example' : 'Ejemplo',
    'results' : 'resultados',
    'of' : 'de',
    'sec' : 'seg',
    'First' : 'Primera',
    'Last' : 'Última',
    'Previous' : 'Anterior',
    'Next' : 'Siguiente',
    'Need more information or support?' : 'Necesita mas informacion o soporte?',
    'Launch Finder' : 'Abrir Finder',
    'Delete' : 'Borrar',
    'Save' : 'Guardar',
    'Sabre Central Quick - Feedback' : 'Sabre Central Quick - Opinión',
    'If you would like to provide feedback on Quick Look, choose one of the features from the drop-down and provide your comments below.' :  'Si quiere enviarnos un comentario sobre Quick Look, escoja una de las opciones del menú y escriba debajo',
    'Retrieving Sabre Red Workspace Information' : 'Obteniendo Informacion de Sabre Red Workspace',
    'Retrieving User Information' : 'Obteniendo información de Usuario',
    'Authenticating..' : 'Autenticando',
    'Almost there..' : 'Casi listo..',
    'Complete!' : 'Completo!',
    'Settings/Preferences' : 'Ajustes/Preferencias',
    'Error Listener Notification' : 'Notificación de Reacción a Errores',
    'Feedback Form' : 'Formulario de Opinión',
    'Language' : 'Idioma',
    'Feature' : 'Funcionalidad',
    'Bookmarks' : 'Marcadores',
    'Notes' : 'Notas',
    'Enter your comments here' : 'Ingrese sus comentarios aquí',
    'Send' : 'Enviar',
    'Comments' : 'Comentarios',
    'Cancel' : 'Cancelar',
    'Feedback' : 'Comentarios'
};