import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'convertHtml' })
export class convertHtmlPipe implements PipeTransform {
  constructor() {}
  transform(str) {

    // console.log(" str ", str)
    //let result = str.replace(/<[^>]*>/g, '')
    let result = this.htmltoText(str)
    //let result = str

    if (result.length > 250) {
      result = result.substring(1,250) +"..."
    }

    // console.log(" result ", result)

     return result;

  }

  private htmltoText (html: string)
  {
    let text = html;
    text = text.replace(/\n/gi, "");
    text = text.replace(/<style([\s\S]*?)<\/style>/gi, "");
    text = text.replace(/<style([\s\S]*?)<\/style>/gi, "");
    text = text.replace(/<strong([\s\S]*?)<\/strong>/gi, "");
    text = text.replace(/<a.*?href="(.*?)[\?\"].*?>(.*?)<\/a.*?>/gi, " $2 $1 ");
    text = text.replace(/<\/div>/gi, "\n\n");
    text = text.replace(/<\/li>/gi, "\n");
    text = text.replace(/<li.*?>/gi, "  *  ");
    text = text.replace(/<\/ul>/gi, "\n\n");
    text = text.replace(/<\/p>/gi, "\n\n");
    text = text.replace(/<br\s*[\/]?>/gi, "\n");
    text = text.replace(/<[^>]+>/gi, "");
    text = text.replace(/^\s*/gim, "");
    text = text.replace(/ ,/gi, ",");
    text = text.replace(/ +/gi, " ");
    text = text.replace(/\n+/gi, "\n\n");
    return text;
  };

}
