

export const LANG_RO_TRANS = {
    'Hello': 'Buna ziua',
    'Select a topic': 'Selectati o optiune',
    'How can we help you today': 'Cum va pot ajuta',
    'Start Chat': 'Incepe Chat-ul',
    'What is your phone number': 'Care este numarul dumneavoastra de telefon',
    'What is your email': 'Care este adresa dumneavoastra de e-mail',
    'Help': 'Ajutor',
    'First name': 'Numele mic',
    'Last name': 'Numele de familie',
    'Record Locator': 'Record locator',
    'Lniata': 'Lniata',
    'Chat is closed': 'Chat-ul este inchis',
    'Chat session has ended': 'Sesiunea de chat a fost terminata',
    'Thank you for contacting us': 'Multumim pentru ca n-ati contactat',
    'Unable to start chat session': 'Nu este posibila inceperea sesiunii de chat',
    'Formats': 'Formaturi',
    'Applications Support': 'Suport pentru aplicatii',
    'Sabre Red Workspace & Hardware': 'Sabre Red Workspoce si Hardware',
    'TripCase and Virtual Payments': 'Tripcase si Virtual Payments'
};