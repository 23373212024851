

export const LANG_EN_TRANS = {
    'English': 'English',
    'Spanish': 'Spanish',
    'German': 'German',
    'French': 'French',
    'Italian': 'Italian',
    'Portuguese': 'Portuguese',
    'Your token is not valid. Please try to login again' : 'Your token is not valid. Please try to login again',
    'ARTICLES_BTN' : 'Articles',
    'FORMATS_BTN' : 'Commands',
    'NOTES_BTN' : 'Notes',
    'BOOKMARKS_BTN' : 'Bookmark',
    'Search' : 'Search',
    'What do you want to search?' : 'What do you want to search?',
    'Searching' : 'Searching',
    'Article Search' : 'Article Search',
    'Format Search' : 'Command Search',
    'Description': 'Description',
    'Tags': 'Tags',
    'STARTS_WITH_BTN' : 'Starts with',
    'ENDS_WITH_BTN' : 'Ends with',
    'CONTAINS_BTN' : 'Contains',
    "ADD_STAR_TIP" : "Add to Bookmarks",
    "ADD_NOTE_TIP" : "Add a Note"
};