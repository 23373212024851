

export const LANG_SV_TRANS = {
    'Hello': 'Hej!',
    'Select a topic': 'Välj ett ämne',
    'How can we help you today': 'Hur kan vi hjälpa dig idag',
    'Start Chat': 'Starta chatt',
    'What is your phone number': 'Vad har du för telefonnummer',
    'What is your email': 'Vad är din e-postadress',
    'Help': 'Hjälp',
    'First name': 'Förnamn',
    'Last name': 'Efternamn',
    'Record Locator': 'Bokningsnummer',
    'Lniata': 'Lniata',
    'Chat is closed': 'Chatten är stängd',
    'Chat session has ended': 'Chat-sessionen har slutat',
    'Thank you for contacting us': 'Tack för att du kontaktade oss',
    'Unable to start chat session': 'Det gick inte att starta chatt-sessionen',
    'Formats': 'Format',
    'Applications Support': 'Applikation Support',
    'Sabre Red Workspace & Hardware': 'Saber Red Workspace och Hårdvara',
    'TripCase and Virtual Payments': 'TripCase och Virtual Betalningar'
};