import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Header } from './../../../../node_modules/sabre-spark';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {
  public href: string = "";
  constructor(private router: Router) { }

  ngOnInit() {
    window.history.pushState('', '', '/');
    this.href = this.router.url;
    //console.log("la URL es: " + this.href);

    //fixing the menu to not showing the hamburger first
    var headerEL = document.getElementById('data-toast-header');
    //headerEL.className = "spark-header spark-header--overflow-checked spark-header--visible";
    let params = { 
      breakpoints: {
        'sm': { min: 0,  max: 850 },
        'md': { min: 851, max: 5000 }
      }
    }
    let header = new Header(headerEL, params);
    header.update();
  }

  getErrorText(){
    var errorMessage = "";

    switch (this.href){
      case '/error':
        errorMessage = "Uh-Oh! There was an authentication issue, please try to login again and re open the app";
        break;
      case '/errorinvalidtoken':
        errorMessage = "Your token is not valid. Please try to login again";
        break;
      case '/errornotallowed':
        errorMessage = "You're not allowed to use this app outside Sabre Red Workspace 360"
        break;
      case '/errortimeout':
        errorMessage = "Application take too much time to load. Please Close it and open it again"
        break;
      default:
        errorMessage = "Sorry, Something went wrong. Please Close it and open it again";
    }

    return errorMessage;
  }

}
