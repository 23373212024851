import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { TranslateService } from './services/Translate/translate.service';
import { TranslatePipe } from './services/Translate/translate.pipe';
import { TRANSLATION_PROVIDERS } from './services/Translate/translate';
import { HttpModule } from '@angular/http';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './views/error/error.component';
import { FormatSearchComponent } from './views/search/format-search/format-search.component';
import { KnolowedgeOwlSearchComponent } from './views/search/knolowedge-owl-search/knolowedge-owl-search.component';
import { SearchComponent } from './views/search/search/search.component';
import {KnowledgeOwlHelperService} from './services/knowledge-owl-helper.service';
import { SearchResultComponent } from './views/search/search-result/search-result.component';
import { SafePipe } from './services/safePipe';
import { SearchResultFormatComponent } from './views/search/search-result/search-result-format.component';
import { SearchPaginationResultsComponent } from './views/search/search-pagination/search-pagination-results.component';
import { SearchPaginationComponent } from './views/search/search-pagination/search-pagination.component';
import { SignInComponent } from './views/signin/signin.component';
import { SearchResultErrorComponent } from './views/search/search-result/search-result-error.component';
import { ErrorSearchComponent } from './views/search/error-search/error-search.component';
import { convertHtmlPipe } from './services/convertHtmlPipe';

@NgModule({
  declarations: [
    AppComponent,
    TranslatePipe,
    ErrorComponent,
    SignInComponent,
    FormatSearchComponent,
    KnolowedgeOwlSearchComponent,
    FormatSearchComponent,
    SearchResultFormatComponent,
    SearchResultErrorComponent,
    SearchComponent,
    SearchResultComponent,
    SafePipe,
    SearchPaginationResultsComponent,
    SearchPaginationComponent,
    ErrorSearchComponent,
    convertHtmlPipe
    ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    HttpModule,
    RouterModule.forRoot([
      { path: 'search', component: SearchComponent },
      { path: 'errorinvalidtoken', component: ErrorComponent },
      { path: 'errornotallowed', component: ErrorComponent },
      { path: 'errortimeout', component: ErrorComponent },
      { path: 'error', component: ErrorComponent },
      { path: 'signin', component: SignInComponent },
      { path: '**', component: ErrorComponent },
    ],
    { scrollPositionRestoration: 'enabled' })
  ],
  providers: [
    TRANSLATION_PROVIDERS,
    TranslateService,
    KnowledgeOwlHelperService,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
