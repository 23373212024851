
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import {KnowledgeOwlHelperService} from '../knowledge-owl-helper.service';
import { Bookmark } from 'src/app/model/FormatDB/Bookmark';
import { environment } from 'src/environments/environment';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class BookmarkService {

  private _apiUrl = "";
  private bookmarks = {} as IDictionaryBookmarks;
  private apiTO = environment.apiTimeOut;
  
  constructor(private _koHelperService : KnowledgeOwlHelperService,private http: Http) { 
    this._apiUrl = _koHelperService.getUrl();
  }

  /**
   * Get all Bookmarks for current user
   */
  public getBookmarks() : IDictionaryBookmarks {
    return this.bookmarks;
}

  /**
   * Load all Bookmarks for an user
   * @param userId 
   */
  public loadBookmarks( userId: string) {
      this.loadDBBookmarks(userId)
    .subscribe(bookmarks=> this.processBookmarks(bookmarks));
  }

  /**
   * Check if the Bookmark exists or not
   * @param id 
   */
   public isBookmarked(id: string): boolean {
    return this.bookmarks[id] !== undefined;
  }

  /**
 * Create a new Bookmark for the user, for the article/format of a specific type
 * @param userId 
 * @param pageID 
 * @param type 
 */
  public createBookmark(userId: string, pageID: string, type: string) {
    let url = this._apiUrl + '/createBookmark';
    const body = {
      'USER_ID': userId,
      'PAGE_ID': pageID,
      'TYPE': type
    };
    this.http.post(url, body, this._koHelperService.getHeaders())
      .pipe(
        timeout(this.apiTO))
      .subscribe((response) => {
        this.bookmarks[pageID] = body;
        // console.log(response)
      });
  }

  /**
   * Delete a  Bookmark for the user, for the article/format of a specific type
   * @param userId 
   * @param pageID 
   * @param type 
   */
  public deleteBookmark(userId: string, pageID: string, type: string) {
    let url = this._apiUrl + '/deleteBookmark';
    const body = {
      'USER_ID': userId,
      'PAGE_ID': pageID,
      'TYPE': type
    };
    this.http.post(url, body, this._koHelperService.getHeaders())
      .pipe(
        timeout(this.apiTO))
      .subscribe((response) => {
         delete this.bookmarks[pageID];
        // console.log(response)
      })
  }

  /**
   * Process the json with all tags and populate the Tags dictionary 
   * @param bookmarks 
   */
  private processBookmarks(bookmarks) {
    bookmarks.forEach((c: any) => {
      this.bookmarks[c.PAGE_ID] = c
     });
    //  console.log("bookmarks ",this.bookmarks)
  }

  /**
   * Gets all Bookmarks for an user
   * @param userId 
   */
  private loadDBBookmarks(userId: string) {
    let url = this._apiUrl + '/getBookmarks?';
    url += "USER_ID=" + userId;
    // console.log("url ", url)
    return this.http.get(url, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractData)
    .catch(this.handleError);
  }

  /**
   * Extract data from API response and convert it to json
   * @param res 
   */
  private extractData(res: Response) {
    if (res.text()) {
      // console.log("res bookmark", res.json().body.data)
        return res.json().body.data;
    } else {
      return {};
    }
  }
;
  private handleError(error: any) {
    //console.log("Error " + error);
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Observable.throw(errMsg);
  }
}

interface IDictionaryBookmarks {
  [index: string]: Bookmark;
}
