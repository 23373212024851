import { SettingsService } from './services/Settings/settings.service';
import { UserNotification } from './model/UserNotification/UserNotification';
import { UserNotificationsService } from './services/UserNotifications/user-notifications.service';
import { NoteService } from './services/FormatsDB/notes.service';
import { BookmarkService } from './services/FormatsDB/bookmarks.service';
import { Component, OnInit, AfterViewInit, OnDestroy, NgZone, Output, EventEmitter } from '@angular/core';
import { TranslateService } from './services/Translate/translate.service';
import { Router } from "@angular/router";
import { KOUserService } from './services/KnowledgeOwl/kouser.service';
import { AuthenticationService } from './services/Authentication/authentication.service';
import { Subscription } from 'rxjs/Subscription';
import { User } from './model/KnowledgeOwl/User';
import { SabreRedMessagesService } from './services/SabreRedMessages/sabre-red-messages.service';
import { ErrorsToReactService } from './services/FormatsDB/errors-to-react.service';
import { Toast } from '../../node_modules/sabre-spark';
import { FeedbackService } from './services/FormatsDB/feedback.service';
import { RestAPIRegisterService } from './services/RestAPI/register.service';
import { Header } from '../../node_modules/sabre-spark';
import { AppSettingsService } from './services/Settings/app-settings.service';
import { ResponseToIgnoreService } from './services/FormatsDB/response-to-ignore.service';

declare var $: any;
declare var OpenModal: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  _authenticationChecked = false;
  _fullmenushown = true;
  private _loadingIndicator = 0.0;
  private _authenticationCallbackSubscription: Subscription = null;
  UserName = "";
  FirstName = "";
  LastName = "";
  UserEmail = "testing@sabre.com";
  ShowFeedBackModal = false;
  commentsFeedback = ""
  // userId = "testing";
  userId = "";
  UserEPR: number;
  UserPCC = "";
  Token = "";
  ErrorListenerNotification = true;

  Languages: { CodeSF: string, Name: string, CodeRed: string }[] = [
    { "CodeSF": "en_us", "Name": "English", "CodeRed": "EN" },
    { "CodeSF": "de", "Name": "German", "CodeRed": "DE" },
    { "CodeSF": "es", "Name": "Spanish", "CodeRed": "ES" },
    { "CodeSF": "fr", "Name": "French", "CodeRed": "FR" },
    { "CodeSF": "it", "Name": "Italian", "CodeRed": "IT" },
    { "CodeSF": "pt_br", "Name": "Portuguese", "CodeRed": "PT" }
  ];

  _topics = ['Feature', 'Bookmarks', 'Notes', 'Search']

  CurrentLanguage = this.Languages[0];

  constructor(private translateService: TranslateService,
    private router: Router,
    private bookmarkService: BookmarkService,
    private noteService: NoteService,
    private koUserService: KOUserService,
    private settingsService: SettingsService,
    private authService: AuthenticationService,
    private restAPIRegisterService: RestAPIRegisterService,
    private ngZone: NgZone,
    private sabreRedMessagesService: SabreRedMessagesService,
    private userNotificationService: UserNotificationsService,
    private errorsToReactService: ErrorsToReactService,
    private responseToIgnoreService: ResponseToIgnoreService,
    private feedbackService: FeedbackService,
    private appSettingsService: AppSettingsService
  ) {
    this.translateService.changeLanguage(this.CurrentLanguage.CodeSF);
  }

  ngOnInit() {
    $('.spark-toast').sparkToast();
    $('.spark-header').sparkHeader();
    $('.spark-expand').sparkExpand();
    $('.spark-popover').sparkPopover();
    $('.spark-progress').sparkProgressIndicator('set', 0.0);

    // setTimeout(()=>this.updateProgressIndicator(), 2000);
    this.updateProgressIndicator();
    this.commentsFeedback = ""

    this.userNotificationService.currentUserNotification.subscribe(
      notification => this.OnUserNotificationRequest(notification)
    );

    window.redAppInterface = window.redAppInterface || {};
    window.redAppInterface.search = window.redAppInterface.search || {};
    window.redAppInterface.formats = window.redAppInterface.formats || {};
    window.redAppInterface.formats.notifyRequest = this.notifyRequest.bind(this);
    window.redAppInterface.formats.notifyResponse = this.notifyResponse.bind(this);
    window.redAppInterface.search.searchError = this.searchError.bind(this);
    window.redAppInterface.search.searchFormat = this.searchFormat.bind(this);
  }


  OnUserNotificationRequest(notification: UserNotification) {
    if (notification && notification.title) {
      this.displayToast(notification);
    }
  }

  searchError(error: string) {
    // console.log('Searching Error ' + error);
    // console.log(' settings ',this.settingsService.getSettings())

    if (this.settingsService.getSettings().ERRORLISTENER) {
      this.ngZone.run(() => this.sabreRedMessagesService.changeArticleSearchError(this.errorsToReactService.getErrorSearchArticle(error)));
    } else {
      //console.log ('Error Listener disabled')
    }
  }

  searchFormat(error: string) {
    // console.log('Searching Format ' + error);
    this.ngZone.run(() => this.sabreRedMessagesService.changeTextSearchFormat(error));
  }

  notifyRequest(request: string) {
    // console.log('Request ' + request);
  }

  notifyResponse(response: string) {
    // console.log('Response ' + response);
    // console.log(' settings ',this.settingsService.getSettings())

    // console.log('settings', this.appSettingsService.getSpecificAppSetting("ErrorFromHostEnabled"))

    if (this.appSettingsService.getSpecificAppSetting("ErrorFromHostEnabled")) {
      if (this.appSettingsService.getSpecificAppSetting("ErrorFromHostEnabled").indexOf('True') >= 0) {
        if (!this.responseToIgnoreService.isResponseToIgnore(response)) {
            this.restAPIRegisterService.setErrorFromHost(response).subscribe(
              response => {
                //console.log(response)
              },
              error => {
                // console.log(error)
              }
            );
        }
      }
    }
    if (this.settingsService.getSettings().ERRORLISTENER) {
      if (this.errorsToReactService.isErrorToReact(response)) {
        OpenModal.OpenSimpleModal('Error: ' + '"' + response + '"', response);
      }
    } else {
      //console.log ('Error Listener disabled')
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.authenticate(), 2500);
  }

  /**
   * Authenticates User
   */
  authenticate() {
    if (this.userId == 'testing') {
      this.OnAuthenticationEvent()
      this.updateProgressIndicator();
    } else {
      this._authenticationCallbackSubscription =
        this.authService.authenticationChangedEvent.subscribe(() => {
          this.OnAuthenticationEvent();
        });
      this.authService.authenticate();
    }
  }

  /**
   * On Authentication Event Callback
   */
  private OnAuthenticationEvent() {
    this.updateProgressIndicator();
    if (this.authService.IsAutenticated || this.userId == 'testing') {
      if (this.userId != 'testing') {
        this.UserName = this.authService.User.FirstName + ' ' + this.authService.User.LastName;
        this.FirstName = this.authService.User.FirstName
        this.LastName = this.authService.User.LastName;
        this.UserEmail = this.authService.User.Email;
        this.UserEPR = this.authService.User.EPR;
        this.UserPCC = this.authService.User.PCC;
        this.Token = this.authService.User.Token;

        // this.Token = 'ATH:Shared/IDL:IceSess\/SessMgr:1\.0.IDL/Common/!ICESMS\/RESA!ICESMSLB\/RES.LB!-3235547969913138557!1403963!0!!E2E-1'
        //  this.Token = 'Shared/IDL:IceSess\/SessMgr:1\.0.IDL/Common/!ICESMS\/RESG!ICESMSLB\/RES.LB!1555952877788!8296!13'
        if (this.Languages.includes(this.Languages.find(x => x.CodeRed == this.authService.User.Language))) {
          this.CurrentLanguage = this.Languages.find(x => x.CodeRed == this.authService.User.Language);
          this.translateService.changeLanguage(this.CurrentLanguage.CodeSF);
        }
        else {
          this.changeLanguage(this.Languages[0].CodeSF);
        }



        this.restAPIRegisterService.register(this.Token, this.UserEPR, this.UserPCC).subscribe(
          response => this.OnGetRegisterRestAPI(response),
          error => this.mapGetUserErrorResponse(error)
        );
        //        console.log("UserEmail ", this.UserEmail)
      }
    }
    else {
      this._authenticationChecked = true
      this._fullmenushown = false;
      // console.log('NeedsToSignIn ', this.authService.NeedsToSignIn)
      if (this.authService.NeedsToSignIn) {
        this.router.navigate(['/signin'])
      }
      if (this.authService.NotAllowedToUseApp) {
        this.router.navigate(['/errornotallowed']);
      }
    }
  }

  private OnGetRegisterRestAPI(response: string) {
    this.updateProgressIndicator();
    // console.log('response', response)

    if (parseInt(response) != 200) {
      this._authenticationChecked = true
      this._fullmenushown = false;
      //console.log('not valid')
      this.router.navigate(['/errorinvalidtoken']);
    } else {
      // Validate if the user exists on DB
      // console.log('OnGetRegisterRestAPI epr:', this.UserEPR, ' pcc', this.UserPCC)
      if (this.UserPCC && this.UserPCC !== "" && this.UserEPR && this.UserEPR !== 0) {
        //console.log('check user')
        this.koUserService.getUserByEPRPCC(this.UserEPR, this.UserPCC).subscribe(
          response => this.OnGetDBUserNameEvent(response),
          error => this.mapGetUserErrorResponse(error)
        );
      }
    }
  }


  private OnGetDBUserNameEvent(users: User[]) {

    // console.log("OnGetDBUserNameEvent user: ", users)

    // console.log('users ', users.length)
    if (users.length > 0) {
      this.loadUserData(users)
    } else {
      //      console.log("create user: ", this.UserEmail)
      // If User doesn't exist, we need to create it
      this.koUserService.postUserByUsername(this.UserEmail, this.FirstName, this.LastName, this.UserEPR, this.UserPCC).subscribe(
        response => this.OnCreateDBUserEvent(response),
        error => this.OnCreateDBUserErrorResponseEvent(error)
      );
    }
  }

  private OnCreateDBUserEvent(users: User[]) {
    // console.log("users ", users)

    if (users.length > 0) {
      this.loadUserData(users)
    } else {
      this._authenticationChecked = true
      this._fullmenushown = false;
      //console.log('Estamos en OnCreateKOUserEvent');
      this.router.navigate(['/error']);
    }
  }

  private loadUserData(users: User[]) {
    this.updateProgressIndicator();

    this.koUserService.setCurrentUser(users[0]);
    this.errorsToReactService.getErrorsToReact();
    this.responseToIgnoreService.getResponsesToIgnore();
    this.bookmarkService.loadBookmarks(users[0].USER_ID);
    this.noteService.getNotes(users[0].USER_ID);
    this.settingsService.loadSettings(users[0].USER_ID).subscribe(
      response => {
        // console.log('response ', response)
        this.settingsService.setSettings(response)
        this.ErrorListenerNotification = response.ERRORLISTENER
        this.updateProgressIndicator();
        this._authenticationChecked = true
        //fixing the menu to not showing the hamburger first
        var headerEL = document.getElementById('data-toast-header');
        //headerEL.className = "spark-header spark-header--overflow-checked spark-header--visible";
        let params = {
          breakpoints: {
            'sm': { min: 0, max: 850 },
            'md': { min: 851, max: 5000 }
          }
        }
        let header = new Header(headerEL, params);
        header.update();

        this.appSettingsService.getAppSettings();

        this.router.navigate(['/search']);

      },
      error => this.mapGetUserErrorResponse(error))
  }

  private mapGetUserErrorResponse(error: any) {
    //console.log('mapGetUserErrorResponse' + error);
    this._authenticationChecked = true;
    this._fullmenushown = false;
    if (error.indexOf('Timeout') >= 0) {
      this.router.navigate(['/errortimeout']);
    }
    else {
      this.router.navigate(['/error']);
    }

  }

  private OnCreateDBUserErrorResponseEvent(error: any) {
    //console.log('OnCreateKOUserErrorResponseEvent')
    this._authenticationChecked = true;
    this._fullmenushown = false;
    if (error.indexOf('Timeout') >= 0) {
      this.router.navigate(['/errortimeout']);
    }
    else {
      this.router.navigate(['/error']);
    }
  }

  /**
   * Updates Authentication Progress Indicator
   */
  private updateProgressIndicator() {
    this._loadingIndicator += 0.2;
    $('.spark-progress').sparkProgressIndicator('set', this._loadingIndicator);

    if (this._loadingIndicator >= 1) {
      this._authenticationChecked = true;
    }
  }

  // private updateProgressIndicator() {
  //   this._loadingIndicator += 0.2;
  //   $('.spark-progress').sparkProgressIndicator('set', this._loadingIndicator);

  //   if (this._loadingIndicator <= 1) {
  //     setTimeout(()=>this.updateProgressIndicator(), 1500);
  //   } else {
  //     this._authenticationChecked = true;
  //   }
  // }


  changeLanguage(language: any) {
    this.translateService.changeLanguage(language.CodeSF);
    this.CurrentLanguage = language;
    // console.log("this.CurrentLanguage", this.CurrentLanguage)
    this.ngZone.run(() => this.translateService.onLanguageChange(language));
  }

  displayToast(notification: UserNotification) {

    // console.log("notification ", notification)
    var toasts = document.querySelector('[data-toast="header"]');

    var toast = new Toast(toasts, {
      elHeader: document.querySelector('[data-toast="header"] .spark-header__nav'),
      title: notification.title,
      icon: notification.icon,
      actionLabel: notification.actionLabel,
      duration: notification.duration,
      actionIcon: notification.actionIcon,
      details: notification.details,
      type: notification.type
    });

    toast.open()

  }

  displayWarningToast() {
    const notification = this.userNotificationService.createWarningNotification(
      this.translateService.instant('Error'),
      this.translateService.instant('Cannot Save Settings. Please try again.'));

    this.ngZone.run(() => this.userNotificationService.newUserNotification(notification));
  }

  OpenFeedBackForm() {
    this.commentsFeedback = ""
    this.ShowFeedBackModal = !this.ShowFeedBackModal
  }

  CloseFeedbackModal() {
    this.ShowFeedBackModal = false;
  }

  SendFeedBack(topicId: number, comments: string) {
    if (!this.userId && this.koUserService.getCurrentUser()) {
      this.userId = this.koUserService.getCurrentUser().USER_ID;
      // console.log ("userId ", this.userId)
    }

    // console.log("topicId:", topicId, "  comments ", comments)
    if (topicId && comments) {
      this.feedbackService.createFeedback(this.userId, this._topics[topicId], topicId, comments)
        .subscribe((response) => {
          // console.log ("response ", response)

          if (response.ok) {
            const notification = this.userNotificationService.createPositiveNotification(
              this.translateService.instant('Success!'),
              this.translateService.instant('Thanks for submitting your feedback.'))

            this.ngZone.run(() => this.userNotificationService.newUserNotification(notification));
          } else {
            const notification = this.userNotificationService.createWarningNotification(
              this.translateService.instant('Warning!'),
              this.translateService.instant('The feedback could not be sent.'))

            this.ngZone.run(() => this.userNotificationService.newUserNotification(notification));
          }
        },
          error => {
            const notification = this.userNotificationService.createWarningNotification(
              this.translateService.instant('Warning!'),
              this.translateService.instant('The feedback could not be sent.'))

            this.ngZone.run(() => this.userNotificationService.newUserNotification(notification));

          }
        )
    }
    this.ShowFeedBackModal = false;
  }

  switchErrorListenerNotifications() {
    // console.log('saving settings', this.koUserService.getCurrentUser())
    this.ErrorListenerNotification = !this.ErrorListenerNotification

    this.settingsService.editSettings(this.koUserService.getCurrentUser().USER_ID,
      this.ErrorListenerNotification)
      .subscribe(
        (response) => {
          if (response) {
            this.settingsService.getSettings().ERRORLISTENER = this.ErrorListenerNotification
          }
          else {
            //console.log ('error while editing Settings')
          }
        },
        error => {
          this.displayWarningToast(),
            this.ErrorListenerNotification = !this.ErrorListenerNotification
        });
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    this._authenticationCallbackSubscription.unsubscribe();
  }
}
