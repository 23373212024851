/**
 *  User
 */
export class SearchPagination{
    
    public limit: number;

    public page: number;

    constructor() {}
}