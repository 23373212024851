

export const LANG_BG_TRANS = {
    'Hello': 'Здравей',
    'Select a topic': 'Избери тема',
    'How can we help you today': 'Как можем да помогнем',
    'Start Chat': 'Започни чат',
    'What is your phone number': 'Какъв е твоят телефонен номер',
    'What is your email': 'Какъв е твоят имейл',
    'Help': 'Помощ',
    'First name': 'Първо име',
    'Last name': 'Фамилно име',
    'Record Locator': 'Номер на резервация',
    'Lniata': 'Lniata',
    'Chat is closed': 'Чатът е затворен',
    'Chat session has ended': 'Чат сесията изтече',
    'Thank you for contacting us': 'Благодарим, че се свърза с нас',
    'Unable to start chat session': 'Не е възможно започването на чат сесията',
    'Formats': 'Формати',
    'Applications Support': 'Отдел Апликации',
    'Sabre Red Workspace & Hardware': 'Sabre Red Workspace & Хардуер',
    'TripCase and Virtual Payments': 'Tripcase и Виртуални Плащания',
    'Select this option if you need help with a PNR, issuing or exchanging a ticket, pricing an itinerary, or any Sabre format':'Select this option if you need help with a PNR, issuing or exchanging a ticket, pricing an itinerary, or any Sabre format',
    'Select this option if you need assistance with installation, configuration and connectivity of Sabre Red Workspace. Also if you need help with printers or Sabre Java Printing Module' : 'Select this option if you need assistance with installation, configuration and connectivity of Sabre Red Workspace. Also if you need help with printers or Sabre Java Printing Module',
    'Select this option if you have any questions or issue with Trip Case such as flight notifications, email delivery issues, or itinerary configuration assistance. Also, if you need set up help with Virtual Payments or questions regarding fax or card deployment issues':'Select this option if you have any questions or issue with Trip Case such as flight notifications, email delivery issues, or itinerary configuration assistance. Also, if you need set up help with Virtual Payments or questions regarding fax or card deployment issues',
    'Select this option if you need help with any Sabre Application, such as Clipboard, Quality Control and Ticketing, Sabre Cruises, Personal Trainer, Sabre Vacations. Also if you need help with Red Apps or the Red Apps Centre':'Select this option if you need help with any Sabre Application, such as Clipboard, Quality Control and Ticketing, Sabre Cruises, Personal Trainer, Sabre Vacations. Also if you need help with Red Apps or the Red Apps Centre'
};