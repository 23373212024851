import { PageStats } from '../../model/PageStats/PageStats';
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import {Format} from '../../model/FormatDB/Format';
import {KnowledgeOwlHelperService} from '../knowledge-owl-helper.service';
import { TranslateService } from '../Translate/translate.service';
import { FormatResults } from 'src/app/model/FormatDB/FormatResults';
import { environment } from 'src/environments/environment';
import { timeout } from 'rxjs/operators';
import { ErrorResults } from 'src/app/model/FormatDB/ErrorResults';
import { ErrorArticle } from 'src/app/model/FormatDB/ErrorArticle';

@Injectable({
  providedIn: 'root'
})
export class ELKSearchService {

  private _apiUrl = "";
  private apiTO = environment.apiTimeOut;

  constructor(private _koHelperService : KnowledgeOwlHelperService,private http: Http,
    private translateService: TranslateService) {
    this._apiUrl = _koHelperService.getUrl();
  }

  // 'language': this.translateService.currentLang.substr(0,2).toLowerCase(),

  public searchFormat(queryText: string,  limit: number, page: number, query: number = 1): Observable<FormatResults>  {
    queryText = queryText.trim().toLowerCase();
    if (queryText.length > 300) {
      queryText = queryText.substring(0,299);
    }
    let body = {
      'phrase': encodeURIComponent(queryText),
      'language': this.translateService.currentLang(),
      'limit': limit,
      'page': page,
      'query': query
    };

    let url = this._apiUrl + '/elastic/searchFormat';
    console.log("url ",url)
     console.log("body encodeURIComponent",body)
    return this.http.post(url, body, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractDataFormats)
    .do(results => {
      if (results.page_stats) {
        results.page_stats.current_page = page
      } else {
        results.page_stats = new PageStats
        results.page_stats.total_records = 0
        results.page_stats.total_pages = 0
      }
    })
    .catch(this.handleError);
  }

  public searchFormatById(id: String ): Observable<Format[]>  {
    let url = this._apiUrl + '/elastic/searchFormatById';
    const body = {
      'id': id,
      'language': this.translateService.currentLang()
    };
    // console.log("body ",body)
    return this.http.post(url, body, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractData)
    .catch(this.handleError);
  }


  public searchError(queryText: string,  limit: number, page: number ): Observable<ErrorResults>  {
    queryText = queryText.trim();
    if (queryText.length > 300) {
      queryText = queryText.substring(0,299);
    }
    let body = {
      'phrase': queryText,
      'language': this.translateService.currentLang(),
      'limit': limit,
      'page': page
    };

    let url = this._apiUrl + '/elastic/searchError';
    // console.log("url ",url)
    // console.log("body ",body)
    return this.http.post(url, body, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractDataErrors)
    .do(results => {
      if (results.page_stats) {
        results.page_stats.current_page = page
      } else {
        results.page_stats = new PageStats
        results.page_stats.total_records = 0
        results.page_stats.total_pages = 0
      }
    })
    .catch(this.handleError);
  }

  public searchErrorByURL(urlValue: String ): Observable<ErrorArticle[]>  {
    let url = this._apiUrl + '/elastic/searchErrorByURL';
    const body = {
      'url': urlValue,
      'language': this.translateService.currentLang()
    };
    // console.log("body ",body)
    return this.http.post(url, body, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractData)
    .catch(this.handleError);
  }

  /**
   * Extract data from API response and convert it to json
   * @param res
   */
  private extractDataFormats(res: Response): FormatResults {
    let formatResults: FormatResults
    if (res.text()) {
      const jsonResp = res.json();
      formatResults = jsonResp.body;
      // console.log("extractDataFormats lenght ", formatResults.data.length)
      // console.log("extractDataFormats ", formatResults.data)

      // for(let i=0; i <formatResults.data.length; i++) {
      //   let r = formatResults.data[i]
      //   console.log("r ", r)
      //   console.log("AddDomain ", this.AddDomain(r.DESCRIPTION))
      //   console.log("filterChar ",this.filterChar(r.EXAMPLE))
      //   // r.DESCRIPTION = this.AddDomain(r.DESCRIPTION)
      //   // r.EXAMPLE = this.filterChar(r.EXAMPLE)
      // }
    if (formatResults.valid) {
        // console.log("formatResults: ", formatResults)
        return formatResults;
      } else {
        return formatResults;
      }
    } else {
      return formatResults;
    }
  }


    /**
   * Extract data from API response and convert it to json
   * @param res
   */
     private extractDataErrors(res: Response): ErrorResults {
      let errorResults: ErrorResults
      if (res.text()) {
        const jsonResp = res.json();
        // console.log("jsonResp ", jsonResp)
        errorResults = jsonResp.body;
        // errorResults.data.forEach(r => {
        //   r.DESCRIPTION = this.AddDomain(r.DESCRIPTION)
        //   r.EXAMPLE = this.filterChar(r.EXAMPLE)
        // })
        if (errorResults.valid) {
          // console.log("formatResults: ", formatResults)
          return errorResults;
        } else {
          return errorResults;
        }
      } else {
        return errorResults;
      }
    }

    /**
   * Extract data from API response and convert it to json
   * @param res
   */
  private extractData(res: Response) {
    if (res.text()) {
      // console.log("res ", res.json().body.data)
      let response = res.json().body.data
      // response.DESCRIPTION = this.AddDomain(response.DESCRIPTION)
      // response.EXAMPLE = this.filterChar(response.EXAMPLE)

      return response;
    } else {
      return {};
    }
  }

  private handleError(error: any) {
    //console.log("Error " + error);
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Observable.throw(errMsg);
  }


  public filterChar(str: any): any {

    // console.log('filterChar', str)

    let result = str.replace(/&#39;/gi,'\'')

    return result;
  }

  public AddDomain(str: any): any {


    let ref = "/s/article/";
    var re = new RegExp(ref, 'g');
    let result = str.replace(re, environment.SF_ARTICLES+ ref)
    // console.log(' result ', result)
    let ref2 = "https:/\/\central.sabre.comhttps:/\/\central.sabre.com/s/article/";
    re = new RegExp(ref2, 'g');

    result = result.replace(re, environment.SF_ARTICLES+ ref)

    let ref3 = "href";
    re = new RegExp(ref3, 'g');

    result = result.replace(re,  ' (click)="linkSF()" href')


    //  console.log('result', result)

    return result;
  }

  linkSF() {
    // console.log('linkSF AddDomain')
  }


}

export enum FormatSearchSelected {
  Start_With,
  End_With,
  Contains
}

