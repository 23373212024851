export class PageStats {

    /**
     * Indicates the next page to load
     */
    public current_page: number;

    /**
     * Indicates the next page to load
     */
    public next_page: number;

    /**
     * the amount of pages
     */
    public total_pages: number;

    /**
     * the amount of records
     */
    public total_records: number;

    /**
     * the amount of records per page
     */
    public records_per_page: number;

}