import { SearchPaginationService } from '../../../services/SearchPagination/search-pagination';
import { PageStats } from '../../../model/PageStats/PageStats';
import { SabreRedMessagesService } from './../../../services/SabreRedMessages/sabre-red-messages.service';
import { KOUserService } from './../../../services/KnowledgeOwl/kouser.service';
import { Article } from 'src/app/model/KnowledgeOwl/Article';
import { KOCategoriesService } from './../../../services/KnowledgeOwl/ko-categories.service';
import { KOTagsService } from './../../../services/KnowledgeOwl/ko-tags.service';
import { NoteService } from './../../../services/FormatsDB/notes.service';
import { Component, OnInit, Output, EventEmitter, NgZone, HostListener } from '@angular/core';
import { KOArticleSearchService } from '../../../services/KnowledgeOwl/ko-article-search.service';
import { BookmarkService } from 'src/app/services/FormatsDB/bookmarks.service';
import { TranslateService } from './../../../services/Translate/translate.service';
import { SearchPagination } from 'src/app/model/SearchPagination/SearchPagination';
import { UserNotificationsService } from 'src/app/services/UserNotifications/user-notifications.service';

declare var $: any;

@Component({
  selector: 'app-knolowedge-owl-search',
  templateUrl: './knolowedge-owl-search.component.html'
})
export class KnolowedgeOwlSearchComponent implements OnInit {
  @Output() IsLoading = new EventEmitter();
  @Output() OnSearchResultsCreated = new EventEmitter();
  @Output() OnSearchResultsElapsedTimeErrors = new EventEmitter();
  @Output() OnSearchArticlesPageStats = new EventEmitter();

  public _isLoading = false;
  private intialTimestamp: number
  private finalTimestamp: number
  private elapsedTime: string
  SearchValue = "";
  _limit = 10
  _page = 1

  constructor(private koArticleService: KOArticleSearchService,
    private bookmarkService: BookmarkService,
    private koTagsService: KOTagsService,
    private koCategoriesService: KOCategoriesService,
    private noteService: NoteService,
    private koUserService: KOUserService,
    private sabreRedMessagesService: SabreRedMessagesService,
    private searchPaginationService: SearchPaginationService,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private userNotificationService: UserNotificationsService
  ) { }

  ngOnInit() {
    $('.spark-input').sparkTextInput();
    $('.spark-toggle-switch').sparkToggleSwitch();

    this.sabreRedMessagesService.currentArticleSearchError.subscribe(article => {
      if (article && article.ID) {

        // console.log("Article KO ", article)
        let articles = []
        articles.push(article)

        let page_stats = new PageStats()
        page_stats.records_per_page = 1;
        page_stats.next_page = 1;
        page_stats.total_pages = 1;
        page_stats.total_records = 1;
        page_stats.current_page = 1;


        this.OnSearchArticlesPageStats.emit(page_stats);
        this.OnSearchResultsCreated.emit(articles);
        this.OnSearchResultsElapsedTimeErrors.emit("0.01");
        this.SearchValue = ""
      }
    });
    this.translateService.currentLanguageChange
      .subscribe(
        language => {
          if (this.SearchValue != '' && this.SearchValue != null) {
            this.search()
          }
        }
      );

      this.searchPaginationService.currentSearchPaginationErrors.subscribe(
        searchPagination => this.OnSearchPaginationRequest(searchPagination)
      );

  }

  OnSearchPaginationRequest(searchPagination: SearchPagination) {
    if (searchPagination && searchPagination.limit) {
      this.search(searchPagination.limit, searchPagination.page);
    }
  }
  /**
   * Search Method
   */
  search(limit?: number, page?:number) {
    // console.log("limit ", limit, " page ", page)
    // console.log("this._limit ", this._limit, " this._page ", this._page)
    let page_stats = new PageStats
    page_stats.total_records = 0
    this.OnSearchArticlesPageStats.emit(page_stats);
    this.OnSearchResultsElapsedTimeErrors.emit("0");
    if (limit==undefined) {
      limit = this._limit
    } else {
      this._limit = limit
    }
    if (page==undefined) {
      page = this._page
    } else {
      this._page = page
    }
    if (this.SearchValue != "") {
      this.intialTimestamp = Date.now();
      let reader_roles = null;
      if (this.koUserService.getCurrentUser() && this.koUserService.getCurrentUser().reader_roles) {
        reader_roles = this.koUserService.getCurrentUser().reader_roles;
      };

      this._isLoading = true;
      this.IsLoading.emit(this._isLoading);
      this.koArticleService.searchErrors(this.SearchValue,
        reader_roles, limit, page)
        .subscribe(
          response => {
            if (response.page_stats ) {
              response.page_stats.records_per_page = limit
            }
            this.OnGetKoArticles(response.data, response.page_stats)

          },
          error => this.OnGetKoArticlesError(error)
        );

    }
  }

  OnGetKoArticles(articles: Article[], page_stats: PageStats) {
    this._isLoading = false;
    articles.forEach(a => {
      if (a.parents) {
        const category = this.koCategoriesService.getCategory(a.parents[0])
        if (category) {
          a.mainCategory = category.name
        }
      }
      a.bookmark = this.bookmarkService.isBookmarked(a.id);
      const note = this.noteService.getNote(a.id);
      if (note) {
        a.note = note.TEXT;
        a.hasNote = true;
      } else {
        a.hasNote = false;
      }
      if (a.tags) {
        a.tagsName = [];
        a.tags.forEach(t => {
          let tagObject = this.koTagsService.getTag(t);
          if (tagObject) {
            a.tagsName.push(tagObject.name)
          }
        });
      }
      a.isMultiCause = this.koArticleService.isMultipleCause(a.tags);
    });

    this.finalTimestamp = Date.now();

    this.elapsedTime = ((this.finalTimestamp - this.intialTimestamp) / 1000).toFixed(2);
    // console.log("elapsedTime ", this.elapsedTime)

    this.OnSearchResultsCreated.emit(articles);
    this.OnSearchArticlesPageStats.emit(page_stats);
    this.OnSearchResultsElapsedTimeErrors.emit(this.elapsedTime);
    this.IsLoading.emit(this._isLoading);
  }

  OnGetKoBodyArticles(article): string {
    let body = article.current_version.en.text;

    return body;
  }

  OnGetKoArticlesError(error: any) {
    this._isLoading = false;
    this.IsLoading.emit(this._isLoading);
    this.displayWarningToast();
  }

  displayWarningToast() {
    const notification = this.userNotificationService.createWarningNotification(
      this.translateService.instant('Error'),
      this.translateService.instant('The search did not work. Please try again in a moment'));

    this.ngZone.run(() => this.userNotificationService.newUserNotification(notification));
  }

  @HostListener('document:keyup', ['$event'])
  handleInput(event: KeyboardEvent) {
    //console.log("key value " + event.key)
    switch (event.key){
      case '\'':
          this.SearchValue = this.SearchValue.replace('\'','¥');
      break;
      case '[':
          this.SearchValue = this.SearchValue.replace('[', '¤');
      break;
      case '=':
          this.SearchValue = this.SearchValue.replace('=', '*');
      break;
      case '\\':
          this.SearchValue = this.SearchValue.replace('\\', '§');
      break;
      case '`':
          this.SearchValue = this.SearchValue.replace('`', '^');
      break;
      case ']':
          this.SearchValue = this.SearchValue.replace(']', '¶');
      break;
    }
  }


}
