import { PageStats } from '../../model/PageStats/PageStats';
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import {KnowledgeOwlHelperService} from '../knowledge-owl-helper.service';
import { TranslateService } from '../Translate/translate.service';
import { environment } from 'src/environments/environment';
import { timeout } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class RestAPIRegisterService {

  private _apiUrl = "";
  private apiTO = environment.apiTimeOut;

  constructor(private _koHelperService : KnowledgeOwlHelperService,private http: Http,
    private translateService: TranslateService) {
    this._apiUrl = _koHelperService.getUrl();
  }

  public register(token: string, epr: number, pcc: string  ): Observable<string>  {
    let body = {
      'epr': epr,
      'pcc': pcc
    };

    let url = this._apiUrl + '/registerToken';
    // console.log("url ",url)
    // console.log("body ",body)
    return this.http.post(url, body, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractData)
    .catch(this.handleError);
  }


  public setSFClickedLink(link  ): Observable<string>  {
    let body = {
      'url': link,
      'language': this.translateService.currentLang(),
    };

    let url = this._apiUrl + '/setSFClickedLink';
    // console.log("url ",url)
    // console.log("body ",body)
    return this.http.post(url, body, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractData)
    .catch(this.handleError);
  }

  public setErrorFromHost(error  ): Observable<string>  {
    let body = {
      'error': error,
    };

    let url = this._apiUrl + '/setErrorFromHost';
    // console.log("url ",url)
    // console.log("body ",body)
    return this.http.post(url, body, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractData)
    .catch(this.handleError);
  }


    /**
   * Extract data from API response and convert it to json
   * @param res
   */
  private extractData(res: Response): string {
    if (res.text()) {
      //  console.log("res ", res.json())
      return res.json().statusCode;
    } else {
      return "";
    }
  }

  private handleError(error: any) {
    //console.log("Error " + error);
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Observable.throw(errMsg);
  }
}


