import { NoteService } from '../../../services/FormatsDB/notes.service';
import { KOUserService } from '../../../services/KnowledgeOwl/kouser.service';
import { BookmarkService } from '../../../services/FormatsDB/bookmarks.service';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, AfterViewChecked } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Format } from 'src/app/model/FormatDB/Format';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/Settings/app-settings.service';
import { ELKSearchService } from 'src/app/services/ELKFormatSearchService/elkformat-search.service';

declare var $: any;

@Component({
  selector: 'app-search-result-format',
  templateUrl: './search-result-format.component.html',
  styleUrls: ['./search-result-format.component.css']
})
export class SearchResultFormatComponent implements OnInit {
  _formatList: Format[];
  _elapsedTime: string;
  _favoriteMode: boolean;
  ShowKOformatModal = false;
  ShowformatUrl = "";
  DisplayNote = "none";
  currentItem: number;
  NoteText = "";
  userId: string;
  sabreCentralActive = false;

  @Input() set Formats(value: Format[]) {
    if (value && value != undefined) {
      this._formatList = value;
    }
    // for (let i = 0; i < this._formatList.length; i++) {
    //   this._formatList[i].DESCRIPTION = this.elkSearchService.AddDomain(this._formatList[i].DESCRIPTION)
    // }
  if (!this.userId && this.koUserService.getCurrentUser()) {
      this.userId = this.koUserService.getCurrentUser().USER_ID;
    }
    // console.log('formats fixed', this._formatList)
}

  @Input() set ElapsedTimeFormats(value: string) {
    if (value && value != undefined) {
      this._elapsedTime = value;
    } else {
      this._elapsedTime = "0";
    }
    if (!this.userId && this.koUserService.getCurrentUser()) {
      this.userId = this.koUserService.getCurrentUser().USER_ID;
    }

  }

  @Input() set FavoriteMode(value: boolean) {
    if (value && value != undefined) {
      this._favoriteMode = value;
    } else {
      this._favoriteMode = false;
    }
  }

  constructor(
    private bookmarkService: BookmarkService,
    private noteService: NoteService,
    private koUserService: KOUserService,
    public sanitizer: DomSanitizer,
    private elkSearchService: ELKSearchService,
    public appSettingsService: AppSettingsService
    ) {


  }

  ngOnInit() {
    $('.spark-expand').sparkExpand();
    $('.spark-popover').sparkPopover();
    $('.spark-input').sparkTextInput();
    if (this.appSettingsService.getSpecificAppSetting("SabreCentralLinksEnabled")) {
      if (this.appSettingsService.getSpecificAppSetting("SabreCentralLinksEnabled").indexOf('True') >=0){
        this.sabreCentralActive = true;
      }
    }
  }

  callSalesforce() {
    // let url = 'https://scc-tncommunity.cs61.force.com/TNshowcase/s/'
    // window.open(url, '_blank');
  }
  ngOnChanges(event: SimpleChange) {
  }

  createBookmark(format: Format, currentItemIndex: number) {
    let pageId = format.ID.toString();
    let type = 'F';
    // console.log("this.userId ", this.userId)
    if (format.bookmark) {
      // console.log("this._favoriteMode ", this._favoriteMode)
      // console.log("this.currentItemIndex ", currentItemIndex)
      if (this._favoriteMode) {
       this._formatList.splice(currentItemIndex, 1)
        // console.log("_formatList ", this._formatList)
      }
      this.bookmarkService.deleteBookmark(this.userId, pageId, type);
      format.bookmark = false;
    } else {
      this.bookmarkService.createBookmark(this.userId, pageId, type);
      format.bookmark = true;
    }
  }

  saveNote() {
    let pageId = this._formatList[this.currentItem].ID.toString();
    let type = 'F';

    if (this._formatList[this.currentItem].hasNote) {
      this.noteService.editNote(this.userId, pageId, type, this.NoteText);
    } else {
      this.noteService.createNote(this.userId, pageId, type, this.NoteText);
      this._formatList[this.currentItem].hasNote = true
    }

    this._formatList[this.currentItem].note = this.NoteText;

    this.closeNote();
  }

  deleteNote() {
    let pageId = this._formatList[this.currentItem].ID.toString();
    let type = 'F';

    if (this._formatList[this.currentItem].hasNote) {
      this.noteService.deleteNote(this.userId, pageId, type);
      this._formatList[this.currentItem].hasNote = false
      this._formatList[this.currentItem].note = ""
    }
    this.closeNote();
  }

  closeNote() {
    this.currentItem = null;
    this.NoteText = ""
  }

  openNote(format: Format, i: number) {
    this.DisplayNote = "block"
    this.currentItem = i
    this.NoteText = format.note
  }

  viewMoreInSalesforceCommunity(format: Format) {
    //This will not allow to follow the link if not CERT
    if (this.sabreCentralActive){
      window.open(this.createLinkToSFDC(format.URL), '_blank');
    }
  }

  createLinkToSFDC(param: string): string {
    let url;
    if (param == null){
      url = environment.SFDCURL + "finder" + "?mode=embedded";
    }
    else{
      url = environment.SFDCURL +"article/"+ param + "?mode=embedded";
    }
    //console.log('SFDC url ', url)
    return url
  }


  linkSF() {
    // console.log('linkSF command')
  }
}
