import { Injectable, Inject } from '@angular/core';
import { TRANSLATIONS } from './translate'; // import our opaque token
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TranslateService {

  private _currentLang: string;
  private languagechange = new BehaviorSubject('');
  currentLanguageChange = this.languagechange.asObservable();

  public currentLang() {
    return this._currentLang;
  }

  // inject our translations
  constructor( @Inject(TRANSLATIONS) private _translations: any) {
  }

  /**
   * Changes the language of the translation service
   * @param lang language to be applied
   */
  public changeLanguage(lang: string): void {
    // set current language
    this._currentLang = lang;
  }

  private translate(key: string): string {
    // private perform translation
    let translation = key;

    if (this._translations[this._currentLang] && this._translations[this._currentLang][key]) {
      return this._translations[this._currentLang][key];
    }

    return translation;
  }

  public instant(key: string) {
    // call translation
    return this.translate(key);
  }

  public onLanguageChange(lang: string){
    this.languagechange.next(lang);
  }


}
