

export const LANG_DE_TRANS = {
    'English': 'Englisch',
    'Spanish': 'Spanisch',
    'German': 'Deutsch',
    'French': 'Französisch',
    'Italian': 'Italienisch',
    'Portuguese': 'Portugiesisch',
    'Your token is not valid. Please try to login again' : 'Ihr Token ist ungültig. Bitte versuchen Sie, sich noch einmal anzumelden',
    'ARTICLES_BTN' : 'Artikel',
    'FORMATS_BTN' : 'Eingaben',
    'NOTES_BTN' : 'Hinweise',
    'BOOKMARKS_BTN' : 'Lesezeichen',
    'Search' : 'Suche',
    'What do you want to search?' : 'Wonach möchten Sie suchen?',
    'Searching' : 'Die Suche ist im Gange',
    'Article Search' : 'Suche nach Artikeln',
    'Format Search' : 'Suche nach Formaten',
    'Description': 'Beschreibung',
    'Tags': 'Schlagwörter',
    'STARTS_WITH_BTN' : 'Beginnt mit',
    'ENDS_WITH_BTN' : 'Endet mit',
    'CONTAINS_BTN' : 'Beinhaltet',
    "ADD_STAR_TIP" : 'Zu Lesezeichen hinzufügen',
    "ADD_NOTE_TIP" : 'Notiz hinzufügen',
    "View More" : 'Mehr anzeigen',
    "View Less" : 'Weniger anzeigen',
    'Formats' : 'Formate',
    'Commands' : 'Eingaben',
    'Errors' : 'Fehler',
    'Favorites' : 'Favoriten',
    'Loading': 'Bitte warten…',
    'Use all CAPITALS for commands (or part of the command), and lowercase for descriptions' : 'Verwenden Sie bei Formaten (oder Teilen eines Formats) nur GROSSBUCHSTABEN. Kleinbuchstaben verwenden Sie bei Beschreibungen.',
    'Availability' : 'Verfügbarkeit',
    'Paste a copy of the system error and/or error number to search' : 'Geben Sie den Systemfehler und/oder die Fehlernummer in der Suche ein',
    'Search Examples:' : 'Suche nach Beispielen:',
    'COMMAND + description:' : 'FORMAT + Beschreibung:',
    ' WP with air extras' : ' WP mit Air Extras',
    'Partial command:' : 'Teilformat:',
    ' pricing with air extras' : ' Preisberechnung mit Air Extras',
    'Example' : 'Beispiel',
    'results' : 'Ergebnisse',
    'of' : 'von',
    'sec' : 'Sek.',
    'First' : 'Erster',
    'Last' : 'Letzter',
    'Previous' : 'Zurück',
    'Next' : 'Weiter',
    'Need more information or support?' : 'Brauchen Sie mehr Informationen oder Unterstützung?',
    'Launch Finder' : 'Finder starten',
    'Delete' : 'Löschen',
    'Save' : 'Speichern',
    'Sabre Central Quick - Feedback' : 'Sabre Central Quick – Feedback',
    'If you would like to provide feedback on Quick Look, choose one of the features from the drop-down and provide your comments below.' :  'Falls Sie Feedback zu Quick Look hinterlassen möchten, wählen Sie eine der Funktionen aus der Dropdown-Liste und geben Ihr Kommentar ein.',
    'Retrieving Sabre Red Workspace Information' : 'Abruf von Sabre Red Workspace-Informationen',
    'Retrieving User Information' : 'Abruf von Benutzerinformationen',
    'Authenticating..' : 'Authentifizierung…',
    'Almost there..' : 'Wir sind fast fertig…',
    'Complete!' : 'Fertig!',
    'Settings/Preferences' : 'Einstellungen/Präferenzen',
    'Error Listener Notification' : 'Benachrichtigung bei möglichem Fehler',
    'Feedback Form' : 'Feedback-Formular',
    'Language' : 'Sprache',
    'Feature' : 'Funktion',
    'Bookmarks' : 'Lesezeichen',
    'Notes' : 'Hinweise',
    'Enter your comments here' : 'Geben Sie hier Ihre Kommentare ein',
    'Send' : 'Senden',
    'Comments' : 'Kommentare',
    'Cancel' : 'Abbrechen',
    'Feedback' : 'Feedback'
};