import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import {User} from '../../model/KnowledgeOwl/User';
import {KnowledgeOwlHelperService} from '../knowledge-owl-helper.service';
import { environment } from 'src/environments/environment';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KOUserService {

  private _apiUrl = "";
  private _user: User;
  private apiTO = environment.apiTimeOut;

  constructor(private _koHelperService : KnowledgeOwlHelperService,private http: Http) {
    this._apiUrl = _koHelperService.getUrl();

  }

  /**
   * Return current logged user
   */
  public getCurrentUser(): User {
    return this._user;
  }

  public setCurrentUser(user: User) {
    this._user = user;
  }

  /**
   * Gets an KO user by a given ssoid
   * @param ssoid sso id
   */
  getUserByssoid( ssoid : string) : Observable<User[]>{
    let response = this.readUserByssoid(ssoid)
    let response2 = response
     .subscribe(user => this._user=user[0],
       error => this._user = null);

     return response;
  }


  /**
   * Gets an KO user by a given username
   * @param username username
   */
  public getUserByUsername( username : string)  :  Observable<User[]> {

    let response = this.readUserByUsername(username)
    let response2 = response
     .subscribe(user => {
       this._user=user[0]
      //  console.log('user loaded ', this._user)

     },
       error => this._user = null);

     return response;

  }

  /**
   * Gets an user from DB using EPR and PCC
   * @param username username
   */
   public getUserByEPRPCC( EPR : number, PCC: string)  :  Observable<User[]> {

    // console.log('getUserByEPRPCC epr:', EPR, ' pcc', PCC)
    let response = this.readUserByEPRPCC(EPR, PCC)
    let response2 = response
     .subscribe(user => {
       this._user=user[0]
      //  console.log('user loaded ', this._user)

      }
       ,
       error => this._user = null);

     return response;

  }


    /**
   * Create a KO user by a given username
   * @param username username
   */
  public createUserByUsername( username : string, firstName: string, lastName: string, userPCC: string, userEPR ) :  Observable<User[]> {

    // console.log('createUserByUsername epr:', userEPR, ' pcc', userPCC)

    let response = this.postUserByUsername(username, firstName, lastName,userEPR, userPCC )
    let response2 = response
     .subscribe(
       user => {
        this._user=user[0]
        // console.log('user created ', this._user)
        },
       error => this._user = null);

     return response;

  }
  /**
   * Gets an KO user by a given ssoid
   * @param ssoid sso id
   */
  private readUserByssoid( ssoid : string) : Observable<User[]>{
    let url = this._apiUrl + '/getUser?ssoid=' + ssoid;
    return this.http.get(url, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractData)
    .catch(this.handleError);
  }
    /**
   * Gets an KO user by a given username
   * @param username username
   */
  private readUserByUsername( username : string)  : Observable<User[]>{
    let url = this._apiUrl + '/getUser?username=' + username;
    return this.http.get(url, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractData)
    .catch(this.handleError);
  }

  private readUserByEPRPCC( EPR : number, PCC: string)  : Observable<User[]>{
    let url = this._apiUrl + '/getUser?epr=' + EPR+"&pcc="+PCC;
    // console.log("url" , url )
    return this.http.get(url, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractData)
    .catch(this.handleError);
  }

  private extractData(res: Response) {
    if (res.text()) {
      const data = res.json().body.data
      this._user = data[0];
      // console.log("user ",this._user)
      return data;
    }
    else {
      return {};
    }
  }

  public  postUserByUsername( username : string, firstName: String, lastName: String, epr: number, pcc: string )  : Observable<User[]>{
    let url = this._apiUrl + '/addUser';
    let body = {
      'username': username,
      'pw-type': 'temp',
      'password': environment.KO_PW,
      'first_name': firstName,
      'last_name': lastName,
      'epr': epr,
      'pcc': pcc
    };

    // console.log("body ",body)
    return this.http.post(url, body, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractDataCreateUser)
    .catch(this.handleError);
  }

  private extractDataCreateUser(res: Response) {
    if (res.text()) {
      // console.log('data ',res.json())
      const data = res.json().body.data
      return data;
    }
    else {
      return {};
    }
  }

  private handleError(error: any) {
    //console.log("Error " + error);
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Observable.throw(errMsg);
  }
}
