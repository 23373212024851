import { Directive } from '@angular/core';

@Directive({
  selector: '.spark-select'
})
export class SparkSelectInputDirective {

  constructor() { }

}
