import { Component, OnInit } from '@angular/core';
import { Header } from './../../../../node_modules/sabre-spark';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html'
})
export class SignInComponent {
  // implements OnInit {

  constructor() { 
  }

  ngOnInit() {
    window.history.pushState('', '', '/');

    //fixing the menu to not showing the hamburger first
    var headerEL = document.getElementById('data-toast-header');
    //headerEL.className = "spark-header spark-header--overflow-checked spark-header--visible";
    let params = { 
      breakpoints: {
        'sm': { min: 0,  max: 850 },
        'md': { min: 851, max: 5000 }
      }
    }
    let header = new Header(headerEL, params);
    header.update();

  }

}
