

export const LANG_FR_TRANS = {
    'English': 'Anglais' ,
    'Spanish': 'Espagnol' ,
    'German': 'Allemand' ,
    'French': 'Français' ,
    'Italian': 'Italien' ,
    'Portuguese': 'Portugais' ,
    'Your token is not valid. Please try to login again' : 'Votre token n’est pas valide. Veuillez essayer de vous connecter à nouveau' ,
    'ARTICLES_BTN' : 'Articles' ,
    'FORMATS_BTN' : 'Formats' ,
    'NOTES_BTN' : 'Remarques' ,
    'BOOKMARKS_BTN' : 'Signets' ,
    'Search' : 'Rechercher' ,
    'What do you want to search?' : 'Que souhaitez-vous rechercher ?' ,
    'Searching' : 'Recherche en cours' ,
    'Article Search' : 'Recherche d’article' ,
    'Format Search' : 'Recherche de format' ,
    'Description': 'Description' ,
    'Tags': 'Tags' ,
    'STARTS_WITH_BTN' : 'Commence par' ,
    'ENDS_WITH_BTN' : 'Finit par' ,
    'CONTAINS_BTN' : 'Contient' ,
    "ADD_STAR_TIP" : 'Ajouter aux signets' ,
    "ADD_NOTE_TIP" : 'Ajouter une remarque' ,
    "View More" : 'Voir plus' ,
    "View Less" : 'Voir moins' ,
    'Formats' : 'Formats' ,
    'Commands' : 'Formats' ,
    'Errors' : 'Erreurs' ,
    'Favorites' : 'Favoris' ,
    'Loading': 'Chargement en cours' ,
    'Use all CAPITALS for commands (or part of the command), and lowercase for descriptions' : 'Utiliser des MAJUSCULES pour les formats (ou la partie d’un format) et des minuscules pour les descriptions' ,
    'Availability' : 'Disponibilité' ,
    'Paste a copy of the system error and/or error number to search' : 'Coller une copie de l’erreur du système et/ou du numéro d’erreur à rechercher' ,
    'Search Examples:' : 'Exemples de recherche:' ,
    'COMMAND + description:' : 'FORMAT + description:', 
    ' WP with air extras' : ' WP avec Air Extras' ,
    'Partial command:' : 'Format partiel:',
    ' pricing with air extras' : ' tarification avec Air Extras' ,
    'Example' : 'Example' ,
    'results' : 'résultats' ,
    'of' : 'sur' ,
    'sec' : 'sec' ,
    'First' : 'Premier' ,
    'Last' : 'Dernier' ,
    'Previous' : 'Précédent' ,
    'Next' : 'Suivant' ,
    'Need more information or support?' : 'Besoin de plus d’informations ou d’assistance ?' ,
    'Launch Finder' : 'Lancer Finder' ,
    'Delete' : 'Supprimer' ,
    'Save' : 'Enregistrer' ,
    'Sabre Central Quick - Feedback' : 'Sabre Central Quick - Feedback' ,
    'If you would like to provide feedback on Quick Look, choose one of the features from the drop-down and provide your comments below.' :  'Si vous souhaitez partager votre avis sur Quick Look, choisissez une option de la liste déroulante et indiquez vos commentaires ci-dessous.' ,
    'Retrieving Sabre Red Workspace Information' : 'Récupération des données de Sabre Red Workspace :',
    'Retrieving User Information' : 'Récupération des données utilisateur :', 
    'Authenticating..' : 'Authentification...' ,
    'Almost there..' : 'Vous y êtes presque !' ,
    'Complete!' : 'C’est fait !' ,
    'Settings/Preferences' : 'Paramètres/Préférences' ,
    'Error Listener Notification' : 'Notification proactive en cas d’erreur' ,
    'Feedback Form' : 'Formulaire de partage d’avis' ,
    'Language' : 'Langue' ,
    'Feature' : 'Caractéristique' ,
    'Bookmarks' : 'Signets' ,
    'Notes' : 'Remarques' ,
    'Enter your comments here' : 'Saisissez ici vos commentaires' ,
    'Send' : 'Envoyer' ,
    'Comments' : 'Commentaires' ,
    'Cancel' : 'Annuler',
    'Feedback' : 'Feedback'
};