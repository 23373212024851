
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import {KnowledgeOwlHelperService} from '../knowledge-owl-helper.service';
import { Note } from 'src/app/model/FormatDB/Note';
import { environment } from 'src/environments/environment';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class NoteService {

  private _apiUrl = "";
  private notes = {} as IDictionaryNotes;
  private apiTO = environment.apiTimeOut;
  
  constructor(private _koHelperService : KnowledgeOwlHelperService,private http: Http) { 
    this._apiUrl = _koHelperService.getUrl();
  }

  /**
   * Gets all Bookmarks for an user
   * @param userId 
   */
  public getNotes( userId: string) {
      this.loadNotes(userId)
    .subscribe(notes=> this.processNotes(notes));
  }

  /**
   * Check if the Bookmark exists or not
   * @param id 
   */
   public getNote(id: string): Note {
    return this.notes[id];
  }

  /**
 * Create a new Bookmark for the user, for the article/format of a specific type
 * @param userId 
 * @param pageID 
 * @param type 
 */
  public createNote(userId: string, pageID: string, type: string, text: string) {
    let url = this._apiUrl + '/createNote';
    const body = {
      'USER_ID': userId,
      'PAGE_ID': pageID,
      'TYPE': type,
      'TEXT': text
    };
    // console.log("create note ",body )
    this.http.post(url, body, this._koHelperService.getHeaders())
      .pipe(
        timeout(this.apiTO))
      .subscribe((response) => {
        this.notes[pageID] = body;
        // console.log(response)
      });
  }

    /**
 * Create a new Bookmark for the user, for the article/format of a specific type
 * @param userId 
 * @param pageID 
 * @param type 
 */
public editNote(userId: string, pageID: string, type: string, text: string) {
  let url = this._apiUrl + '/editNote';
  const body = {
    'USER_ID': userId,
    'PAGE_ID': pageID,
    'TYPE': type,
    'TEXT': text
};
  this.http.post(url, body, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .subscribe((response) => {
      this.notes[pageID] = body;
      // console.log(response)
    });
}

  /**
   * Delete a  Bookmark for the user, for the article/format of a specific type
   * @param userId 
   * @param pageID 
   * @param type 
   */
  public deleteNote(userId: string, pageID: string, type: string) {
    let url = this._apiUrl + '/deleteNote';
    const body = {
      'USER_ID': userId,
      'PAGE_ID': pageID,
      'TYPE': type
    };
    this.http.post(url, body, this._koHelperService.getHeaders())
      .pipe(
        timeout(this.apiTO))
      .subscribe((response) => {
         delete this.notes[pageID];
        // console.log(response)
      })
  }

  /**
   * Process the json with all tags and populate the Tags dictionary 
   * @param notes 
   */
  private processNotes(notes) {
    notes.forEach((c: any) => {
      this.notes[c.PAGE_ID] = c
     });
    //  console.log("notes ",this.notes)
  }

  /**
   * Gets all Notes for an user
   * @param userId 
   */
  private loadNotes(userId: string) {
    let url = this._apiUrl + '/getNotes?';
    url += "USER_ID=" + userId;
    // console.log("url ", url)
    return this.http.get(url, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractData)
    .catch(this.handleError);
  }

  /**
   * Extract data from API response and convert it to json
   * @param res 
   */
  private extractData(res: Response) {
    if (res.text()) {
        return res.json().body.data;
    } else {
      return {};
    }
  }
;
  private handleError(error: any) {
    //console.log("Error " + error);
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Observable.throw(errMsg);
  }
}

interface IDictionaryNotes {
  [index: string]: Note;
}
