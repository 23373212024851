import { Category } from './../../model/KnowledgeOwl/Category';
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import {KnowledgeOwlHelperService} from '../knowledge-owl-helper.service';
import { environment } from 'src/environments/environment';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class KOCategoriesService {

  private _apiUrl = "";
  private categoriesKO = {} as IDictionaryCategories;
  private apiTO = environment.apiTimeOut;
  
  constructor(private _koHelperService : KnowledgeOwlHelperService,private http: Http) { 
    this._apiUrl = _koHelperService.getUrl();
    this.loadCategories()
    .subscribe(categories=> this.processCategories(categories));
  }

  /**
   * Returns the Category indexed by id
   * @param id 
   */
  public getCategory(id: string): Category {
    return this.categoriesKO[id];
  }

  /**
   * Process the json with all categories and populate the Categories dictionary 
   * @param categories 
   */
  private processCategories(categories) {
    categories.forEach((c: any) => {
      let cat = {
        id: c.id,
        name: c.name['en']
      }
      this.categoriesKO[c.id] = cat
     });
    //  console.log("categories ", this.categoriesKO)
  }

  /**
   * Gets all KO categories
   */
  private loadCategories() {
    let url = this._apiUrl + '/getCategories';
    return this.http.get(url, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractData)
    .catch(this.handleError);
  }

  /**
   * Extract data from API response and convert it to json
   * @param res 
   */
  private extractData(res: Response) {
    if (res.text()) {
      const jsonResp = res.json();
      if (jsonResp.body.valid) {
        return jsonResp.body.data;
      } else {
        return {};
      }
    } else {
      return {};
    }
  }
;
  private handleError(error: any) {
    //console.log("Error " + error);
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Observable.throw(errMsg);
  }
}

interface IDictionaryCategories {
  [index: string]: Category;
}
