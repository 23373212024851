import { SearchPagination } from '../../model/SearchPagination/SearchPagination';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchPaginationService {

  private searchPaginationErrors = new BehaviorSubject(new SearchPagination);
  currentSearchPaginationErrors = this.searchPaginationErrors.asObservable();

  private searchPaginationFormats = new BehaviorSubject(new SearchPagination);
  currentSearchPaginationFormats = this.searchPaginationFormats.asObservable();

  public newSearchPaginationFormats(spFormats: SearchPagination) {
    //  console.log("searchPaginationFormats ", spFormats)
    //  console.log("currentSearchPaginationFormats ", this.currentSearchPaginationFormats)
    if (spFormats) {
      this.searchPaginationFormats.next(spFormats)
    } else {
      //console.error("searchPagination not found!")
    }
  }

  public newSearchPaginationErrors(spErrors: SearchPagination) {
    //  console.log ("spErrors ", spErrors)
    //  console.log("currentSearchPaginationErrors ", this.currentSearchPaginationErrors)
    if (spErrors) {
      this.searchPaginationErrors.next(spErrors)
    } else {
      //console.error("searchPagination not found!")
    }
  }



  // private searchPaginationErrors = new BehaviorSubject(new SearchPagination);
  // currentSearchPaginationErrors = this.searchPaginationErrors.asObservable();

  // private searchPaginationFormats = new BehaviorSubject(new SearchPagination);
  // currentSearchPaginationFormats = this.searchPaginationFormats.asObservable();

  // public newSearchPaginationFormats(spFormats: SearchPagination) {
  //    console.log("searchPaginationFormats ", spFormats)
  //   if (spFormats) {
  //     this.searchPaginationFormats.next(spFormats)
  //   } else {
  //     //console.error("searchPagination not found!")
  //   }
  // }

  // public newSearchPaginationErrors(spErrors: SearchPagination) {
  //    console.log ("spErrors ", spErrors)
  //   if (spErrors) {
  //     this.searchPaginationErrors.next(spErrors)
  //   } else {
  //     //console.error("searchPagination not found!")
  //   }
  // }

  constructor() { }
}

