import { UserNotification } from './../../model/UserNotification/UserNotification';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationsService {

  private userNotification = new BehaviorSubject(new UserNotification);
  currentUserNotification = this.userNotification.asObservable();

  public newUserNotification(notification: UserNotification) {
    if (notification) {
      this.userNotification.next(notification)
    } else {
      //console.error("notification not found!")
    }
  }

  public createPositiveNotification(title: string, details: string): UserNotification {
    const notification = new UserNotification();
    notification.title = title
    notification.icon = 'spark-icon-check'
    notification.actionLabel = ''
    notification.duration = 0.5
    notification.actionIcon = ''
    notification.details = details
    notification.type = 'positive'

    return notification;
  }

  public createWarningNotification(title: string, details: string): UserNotification {
    const notification = new UserNotification();
    notification.title = title
    notification.icon = 'spark-icon-alert-triangle'
    notification.actionLabel = ''
    notification.duration = 2.0
    notification.actionIcon = ''
    notification.details = details
    notification.type = 'warning'

    return notification;
  }

  constructor() { }
}

