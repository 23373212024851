/**
 *  User
 */
export class UserNotification{
    
    public title: string;

    public icon: string;

    public actionLabel: string;

    public duration: number;

    public actionIcon: string;
    
    public details: string;

    public type: string;
}