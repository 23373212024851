import { PageStats } from '../../model/PageStats/PageStats';
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import {Format} from '../../model/FormatDB/Format';
import {KnowledgeOwlHelperService} from '../knowledge-owl-helper.service';
import { TranslateService } from '../Translate/translate.service';
import { FormatResults } from 'src/app/model/FormatDB/FormatResults';

@Injectable({
  providedIn: 'root'
})

export class FormatSearchService {

  
  private _apiUrl = "";
  
  constructor(private _koHelperService : KnowledgeOwlHelperService,private http: Http,
    private translateService: TranslateService) { 
    this._apiUrl = _koHelperService.getUrl();
  }
 
  // public searchFormat(queryText: string, option: FormatSearchSelected, limit: number, page: number ): Observable<FormatResults>  {
  //   let url = this._apiUrl + '/formatSearch';
  //   queryText = queryText.trim();
  //   let regexValue = queryText;
  //   if (option === FormatSearchSelected.Start_With) { 
  //     regexValue = queryText + "%";
  //   }
  //   if (option === FormatSearchSelected.End_With) {
  //     regexValue = "%" + queryText;
  //   }
  //   if (option === FormatSearchSelected.Contains) {
  //     regexValue = "%" + queryText + "%";
  //   }
  //   const body = {
  //     'format': regexValue,
  //     'language': this.translateService.currentLang.substr(0,2).toLowerCase(),
  //     'limit': limit,
  //     'page': page
  //   };    
  //   // console.log("body ",body)
  //   return this.http.post(url, body, this._koHelperService.getHeaders())
  //   .map(this.extractDataFormats)
  //   .do(results => {
  //     if (results.page_stats) {
  //       results.page_stats.current_page = page
  //     } else {
  //       results.page_stats = new PageStats
  //       results.page_stats.total_records = 0
  //       results.page_stats.total_pages = 0
  //     }
  //   })
  // .catch(this.handleError);
  // }

  // public searchFormatByDescription(queryText: string, option: FormatSearchSelected , limit: number, page: number ): Observable<FormatResults>  {
  //   let url = this._apiUrl + '/formatSearchByDescription';
  //   queryText = queryText.trim();
  //   let regexValue = queryText;
  //   if (option === FormatSearchSelected.Start_With) { 
  //     regexValue = queryText + "%";
  //   }
  //   if (option === FormatSearchSelected.End_With) {
  //     regexValue = "%" + queryText;
  //   }
  //   if (option === FormatSearchSelected.Contains) {
  //     regexValue = "%" + queryText + "%";
  //   }
  //   const body = {
  //     'description': regexValue,
  //     'language': this.translateService.currentLang.substr(0,2).toLowerCase(),
  //     'limit': limit,
  //     'page': page
  //   };    
  //   // console.log("body ",body)
  //   return this.http.post(url, body, this._koHelperService.getHeaders())
  //   .map(this.extractDataFormats)
  //   .do(results => {
  //     if (results.page_stats) {
  //       results.page_stats.current_page = page
  //     } else {
  //       results.page_stats = new PageStats
  //       results.page_stats.total_records = 0
  //       results.page_stats.total_pages = 0
  //     }
  //   })
  //   .catch(this.handleError);
  // }

  // public searchFormatById(id: String ): Observable<Format[]>  {
  //   let url = this._apiUrl + '/formatSearchById';
  //   const body = {
  //     'id': id,
  //     'language': this.translateService.currentLang.substr(0,2).toLowerCase()
  //   };    
  //   // console.log("body ",body)
  //   return this.http.post(url, body, this._koHelperService.getHeaders())
  //   .map(this.extractData)
  //   .catch(this.handleError);
  // }


  // /**
  //  * Extract data from API response and convert it to json
  //  * @param res 
  //  */
  // private extractDataFormats(res: Response): FormatResults {
  //   let formatResults: FormatResults
  //   if (res.text()) {
  //     const jsonResp = res.json();
  //     // console.log("jsonResp ", jsonResp)
  //     formatResults = jsonResp.body;
  //     if (formatResults.valid) {
  //       // console.log("formatResults: ", formatResults)
  //       return formatResults;
  //     } else {
  //       return formatResults;
  //     }
  //   } else {
  //     return formatResults;
  //   }
  // }

  //   /**
  //  * Extract data from API response and convert it to json
  //  * @param res 
  //  */
  // private extractData(res: Response) {
  //   if (res.text()) {
  //     // console.log("res ", res.json().body.data)
  //     return res.json().body.data;
  //   } else {
  //     return {};
  //   }
  // }

  // private handleError(error: any) {
  //   console.log("Error " + error);
  //   let errMsg = (error.message) ? error.message :
  //     error.status ? `${error.status} - ${error.statusText}` : 'Server error';
  //   return Observable.throw(errMsg);
  // }
}

export enum FormatSearchSelected {
  Start_With,
  End_With,
  Contains
}