import { AuthenticationService } from './Authentication/authentication.service';
import { Injectable } from '@angular/core';
import {  Headers,RequestOptions } from '@angular/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KnowledgeOwlHelperService {

  constructor(private authenticationService: AuthenticationService) { 

  }

  /**
   * Returns Request Options for Knowledge Owl Service proxy
   */
  getHeaders() : RequestOptions{
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Access-Control-Allow-Methods", "GET, POST, PUT, DELETE, OPTIONS");
    myHeaders.append("Access-Control-Allow-Headers", "Content-Type,x-requested-with,Authorization,Access-Control-Allow-Origin");
    myHeaders.append("Access-Control-Allow-Credentials", "true");
    myHeaders.append("Authorization", "Bearer " + this.authenticationService.getCurrentUser().Token);
    myHeaders.append("tokentype", "ATH");
    //  console.log('myHeaders ', myHeaders)
    return new RequestOptions({ headers: myHeaders });
  } 

  getUrl() : string{
    return environment.apiUrl;
  }
}
