

export const LANG_IT_TRANS = {
    'English': 'Inglese',
    'Spanish': 'Spagnolo',
    'German': 'Tedesco',
    'French': 'Francese',
    'Italian': 'Italiano',
    'Portuguese': 'Portoghese',
    'Your token is not valid. Please try to login again' : 'Il tuo token non è valido. Riprova nuovamente ad accedere' ,
    'ARTICLES_BTN' : 'Articoli' ,
    'FORMATS_BTN' : 'Comandi' ,
    'NOTES_BTN' : 'Note' ,
    'BOOKMARKS_BTN' : 'Preferiti' ,
    'Search' : 'Cerca' ,
    'What do you want to search?' : 'Che cosa desideri cercare?' ,
    'Searching' : 'Ricerca' ,
    'Article Search' : 'Cerca articoli' ,
    'Format Search' : 'Cerca formati' ,
    'Description': 'Descrizione',
    'Tags': 'Etichette (tag)',
    'STARTS_WITH_BTN' : 'Inizia con',
    'ENDS_WITH_BTN' : 'Termina con',
    'CONTAINS_BTN' : 'Contiene' ,
    "ADD_STAR_TIP" : 'Aggiungi ai preferiti' ,
    "ADD_NOTE_TIP" : 'Aggiungi alle note' ,
    "View More" : 'Mostra di più' ,
    "View Less" : 'Mostra di meno' ,
    'Formats' : 'Formati' ,
    'Commands' : 'Comandi' ,
    'Errors' : 'Errori' ,
    'Favorites' : 'Preferiti' ,
    'Loading': 'Caricamento in corso' ,
    'Use all CAPITALS for commands (or part of the command), and lowercase for descriptions' : 'Utilizza caratteri MAIUSCOLI per i comandi (o parte del comando) e minuscoli per le descrizioni' ,
    'Availability' : 'Disponibilità' ,
    'Paste a copy of the system error and/or error number to search' : 'Incolla una copia dell’errore del sistema e/o del numero di errore da cercare' ,
    'Search Examples:' : 'Cerca esempi' ,
    'COMMAND + description:' : 'COMANDO + descrizione' ,
    ' WP with air extras' : ' WPNI con Air Extras' ,
    'Partial command:' : 'Parte del comando' ,
    ' pricing with air extras' : ' quotazione con Air Extras' ,
    'Example' : 'Esempio',
    'results' : 'risultati' ,
    'of' : 'di' ,
    'sec' : 'sec' ,
    'First' : 'Primo' ,
    'Last' : 'Ultimo' ,
    'Previous' : 'Precedente' ,
    'Next' : 'Successivo' ,
    'Need more information or support?' : 'Hai bisogno di ulteriori informazioni o assistenza?' ,
    'Launch Finder' : 'Lancia Finder' ,
    'Delete' : 'Elimina' ,
    'Save' : 'Salva' ,
    'Sabre Central Quick - Feedback' : 'Sabre Central Quick - Feedback' ,
    'If you would like to provide feedback on Quick Look, choose one of the features from the drop-down and provide your comments below.' : 'Se desideri fornire un feedback su Quick Look, scegli una delle funzionalità dal menu a tendina e lascia un commento nella sezione più in basso.' ,
    'Retrieving Sabre Red Workspace Information' : 'Recupero delle informazioni su Sabre Red Workspace' ,
    'Retrieving User Information' : 'Recupero delle informazioni sull’utente' ,
    'Authenticating..' : 'Autenticazione in corso…' ,
    'Almost there..' : 'Ci siamo quasi…' ,
    'Complete!' : 'Completata!' ,
    'Settings/Preferences' : 'Impostazioni/Preferenze' ,
    'Error Listener Notification' : 'Notifica proattiva in caso di errori' ,
    'Feedback Form' : 'Modulo di feedback' ,
    'Language' : 'Lingua' ,
    'Feature' : 'Funzionalità' ,
    'Bookmarks' : 'Preferiti' ,
    'Notes' : 'Note' ,
    'Enter your comments here' : 'Inserisci qui i tuoi commenti' ,
    'Send' : 'Invia' ,
    'Comments' : 'Commenti' ,
    'Cancel' : 'Annulla' ,
    'Feedback' : 'Feedback'
};