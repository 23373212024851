export class ErrorArticle {
    /**
     * Format Unique ID
     */
    public ID : string;

    /**
     * Language code 2 characters
     */
    public LANGUAGE: string;

    /**
     * Format text
     */
    public FORMAT: string;

    /**
     * Format Example
     */
    public EXAMPLE: string;

    /**
     * Format Description
     */
    public DESCRIPTION: string;

    /**
     * KnowledgeOwl Link URL
     */
    public URL: string;

    /**
     * Error Master Version Id from Salesforce
     */
     public MASTERVERSIONID: string;

        /**
     * Indicate if the Format has been bookmarked
     */
    public bookmark: boolean;

    /**
     * Indicate if the Format has a note
     */
    public note: string;

    /**
     * Indicate if the Format has a note
     */
    public hasNote: boolean;

    /* Indicate if the Error is a MultiCause
    */
        public isMultiCause: boolean;

  }
