
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import {KnowledgeOwlHelperService} from '../knowledge-owl-helper.service';
import { Feedback } from 'src/app/model/FormatDB/Feedback';

@Injectable({
  providedIn: 'root'
})

export class FeedbackService {

  private _apiUrl = "";
  private bookmarks = {} as IDictionaryFeedback

  constructor(private _koHelperService : KnowledgeOwlHelperService,private http: Http) {
    this._apiUrl = _koHelperService.getUrl();
  }

  /**
 * Create a new Feedback for the user
 * @param userId
 * @param topic
 * @param comments
 */
  public createFeedback(userId: string, topic: string, topicId: number, comments: string): Observable<Response> {
    let url = this._apiUrl + '/createFeedback';
    const body = {
      'USER_ID': userId,
      'TOPIC': topic,
      'TOPIC_CODE': topicId,
      'COMMENTS': comments
    };
    return this.http.post(url, body, this._koHelperService.getHeaders())
      // .subscribe((response) => {
      //   console.log(response)
      // });
  }

}
interface IDictionaryFeedback {
  [index: string]: Feedback;
}
