import { KOArticleSearchService } from '../KnowledgeOwl/ko-article-search.service';
import { ErrorsToReact } from './../../model/FormatDB/ErrorsToReact';
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import {KnowledgeOwlHelperService} from '../knowledge-owl-helper.service';
import { environment } from 'src/environments/environment';
import { timeout } from 'rxjs/operators';
import { ELKSearchService } from '../ELKFormatSearchService/elkformat-search.service';
import { ErrorArticle } from 'src/app/model/FormatDB/ErrorArticle';

@Injectable({
  providedIn: 'root'
})

export class ErrorsToReactService {

  private _apiUrl = "";
  private errorsToReact = {} as IDictionaryErrorsToReact;
  private apiTO = environment.apiTimeOut;

  constructor(private _koHelperService : KnowledgeOwlHelperService,
    private elkSearchService: ELKSearchService,
    private koArticleService: KOArticleSearchService,
    private http: Http) {
    this._apiUrl = _koHelperService.getUrl();
  }

  /**
   * Gets all Errors to React
   * @param
   */
  public getErrorsToReact() {
      this.loadErrorsToReact()
    .subscribe(errors=> this.processErrorsToReact(errors));
  }

   /**
   * Check if the error exists in the Error dictionary
   * @param error
   */
  public isErrorToReact(error: string): boolean {
    // console.log("error ", error)
    for (const key in this.errorsToReact) {
      // console.log (' search ',this.errorsToReact[key].ERROR)
      if (error.indexOf(this.errorsToReact[key].ERROR) !== -1) {
        return true;
      }
    }
    return false;
  }

   /**
   * Get the KO Article KO from the Error dictionary
   * @param error
   */
  public getErrorSearchArticle(error: string): ErrorArticle {
    for (const key in this.errorsToReact) {
      if (error.indexOf(this.errorsToReact[key].ERROR) !== -1) {

        // console.log("getErrorSearchArticle",this.errorsToReact[key].article )
        return this.errorsToReact[key].article;
      }
    }
    return null
  }


  /**
   * Process the json with all tags and populate the Tags dictionary
   * @param errors
   */
  private processErrorsToReact(errors) {
    errors.forEach((e: ErrorsToReact) => {
      //  console.log("Error e: ",e)

       this.elkSearchService.searchErrorByURL(e.SEARCH)
      .subscribe(
        response => {
          // console.log("article e: ",response)

          e.article = response[0]
        },
        error => e.article = null
      );
      // console.log("article ", e);
      this.errorsToReact[e.ERROR] = e
     });
    //  console.log("errors ",this.errorsToReact)
  }

  /**
   * Gets all Errors to React from DB
   * @param
   */
  private loadErrorsToReact() {
    let url = this._apiUrl + '/getErrorsToReact';
    // console.log("url ", url)
    return this.http.get(url, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractData)
    .catch(this.handleError);
  }

  /**
   * Extract data from API response and convert it to json
   * @param res
   */
  private extractData(res: Response) {
    if (res.text()) {
        return res.json().body.data;
    } else {
      return {};
    }
  }
;
  private handleError(error: any) {
    //console.log("Error " + error);
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Observable.throw(errMsg);
  }
}

interface IDictionaryErrorsToReact {
  [index: string]: ErrorsToReact;
}
