export class Settings {
    /**
     * KO User ID
     */
    public USER_ID : string;

    /**
     * True is Error Listener Notification is Disabled
     */
    public ERRORLISTENER: boolean;

}