import { InjectionToken } from '@angular/core';

// import translations
import {  LANG_EN_TRANS } from './languages/lang-en-US';
import {  LANG_ES_TRANS } from './languages/lang-es-ES';
import {  LANG_DE_TRANS } from './languages/lang-de-DE';
import {  LANG_BG_TRANS } from './languages/lang-bg-BG';
import {  LANG_GB_TRANS } from './languages/lang-en-GB';
import {  LANG_FR_TRANS } from './languages/lang-fr-FR';
import {  LANG_IT_TRANS } from './languages/lang-it-IT';
import {  LANG_NL_TRANS } from './languages/lang-nl-NL';
import {  LANG_BR_TRANS } from './languages/lang-pt-BR';
import {  LANG_PT_TRANS } from './languages/lang-pt-PT';
import {  LANG_RO_TRANS } from './languages/lang-ro-RO';
import {  LANG_SV_TRANS } from './languages/lang-sv-SE';
import {  LANG_TR_TRANS } from './languages/lang-tr-TR';
import {  LANG_ZA_TRANS } from './languages/lang-zu-ZA';


// translation token
export const TRANSLATIONS = new InjectionToken('translations');

// all translations
const dictionary = {
    'en_us': LANG_EN_TRANS,
    'es': LANG_ES_TRANS,
    'bg-BG': LANG_BG_TRANS,
    'en-GB': LANG_GB_TRANS,
    'fr': LANG_FR_TRANS,
    'it': LANG_IT_TRANS,
    'nl-NL': LANG_NL_TRANS,
    'pt_br': LANG_BR_TRANS,
    'PT': LANG_PT_TRANS,
    'ro-RO': LANG_RO_TRANS,
    'sv-SV': LANG_SV_TRANS,
    'tr-TR': LANG_TR_TRANS,
    'zu-ZA': LANG_ZA_TRANS,
    'de': LANG_DE_TRANS,
};

// providers
export const TRANSLATION_PROVIDERS = [
    { provide: TRANSLATIONS, useValue: dictionary },
];
