

export const LANG_NL_TRANS = {
    'Hello': 'Hello',
    'Select a topic': 'Kies een onderwerop',
    'How can we help you today': 'Hoe kunnen we U vandaag helpen',
    'Start Chat': 'Start Chat',
    'What is your phone number': 'Mag ik uw telefoonnummer',
    'What is your email': 'Mag ik uw email adres',
    'Help': 'Help',
    'First name': 'Voornaam',
    'Last name': 'Familienaam',
    'Record Locator': 'Recod locator',
    'Lniata': 'Lniata',
    'Chat is closed': 'Chat is dicht',
    'Chat session has ended': 'Chat versie sloot',
    'Thank you for contacting us': 'Bedankt om ons te contacteren',
    'Unable to start chat session': 'Onmogelijk on de chat te openen',
    'Formats': 'Formats',
    'Applications Support': 'Applications Support',
    'Sabre Red Workspace & Hardware': 'Sabre Red Workspace & Hardware',
    'TripCase and Virtual Payments': 'TripCase and Virtual Payments'
};