import { PageStats } from '../../../model/PageStats/PageStats';
import { Component, OnInit, Input } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-search-pagination-results',
  templateUrl: './search-pagination-results.component.html',
  styleUrls: ['./search-pagination-results.component.css']
})

export class SearchPaginationResultsComponent implements OnInit {
  _elapsedTimeErrors: string;
  _elapsedTimeFormats: string;
  _page_statsErrors: PageStats;
  _page_statsFormats: PageStats;
  _minRecordDisplayErrors: number
  _maxRecordDisplayErrors: number
  _minRecordDisplayFormats: number
  _maxRecordDisplayFormats: number

  @Input() set ElapsedTimeErrors(value: string) {
    if (value && value != undefined) {
      this._elapsedTimeErrors = value;
    } else {
      this._elapsedTimeErrors = "0";
    }
    // console.log("this._elapsedTimeErrors ",this._elapsedTimeErrors)
  }

  @Input() set ElapsedTimeFormats(value: string) {
    if (value && value != undefined) {
      this._elapsedTimeFormats = value;
    } else {
      this._elapsedTimeFormats = "0";
    }
    // console.log("this._elapsedTime ",this._elapsedTimeFormats)
  }

  @Input() set FormatsPageStats(value: PageStats) {
    if (value && value.total_records > 0) {
      this._page_statsFormats = value;
      this._minRecordDisplayFormats = 1 + ((this._page_statsFormats.current_page - 1) * this._page_statsFormats.records_per_page)
      this._maxRecordDisplayFormats = this._minRecordDisplayFormats + this._page_statsFormats.records_per_page - 1
      if (this._maxRecordDisplayFormats > this._page_statsFormats.total_records) {
        this._maxRecordDisplayFormats = this._page_statsFormats.total_records
      }
      // console.log("_page_statsFormats", this._page_statsFormats)
      // console.log("this._minRecordDisplayFormats ", this._minRecordDisplayFormats)
      // console.log("this._maxRecordDisplayFormats ", this._maxRecordDisplayFormats)
    } else {
      this._page_statsFormats = new PageStats
      this._page_statsFormats.records_per_page = 0;
      this._page_statsFormats.next_page = 0;
      this._page_statsFormats.total_pages = 0;
      this._page_statsFormats.total_records = 0;
      this._minRecordDisplayFormats = 0;
      this._maxRecordDisplayFormats = 0;
    }
  }

  @Input() set ErrorsPageStats(value: PageStats) {
    if (value && value.total_records > 0) {
      this._page_statsErrors = value;
      // console.log("_page_stats", this._page_statsErrors)
      this._minRecordDisplayErrors = 1 + ((this._page_statsErrors.current_page - 1) * this._page_statsErrors.records_per_page)
      this._maxRecordDisplayErrors = this._minRecordDisplayErrors + this._page_statsErrors.records_per_page - 1
      if (this._maxRecordDisplayErrors > this._page_statsErrors.total_records) {
        this._maxRecordDisplayErrors = this._page_statsErrors.total_records
      }
      // console.log("this._minRecordDisplayErrors ", this._minRecordDisplayErrors)
      // console.log("this._maxRecordDisplayErrors ", this._maxRecordDisplayErrors)
      // console.log("_ResultsLimit ", this._ResultsLimit)
      // console.log("_ResultsLimit ", this._ResultsLimit)
    } else {
      this._page_statsErrors = new PageStats
      this._page_statsErrors.records_per_page = 0;
      this._page_statsErrors.next_page = 0;
      this._page_statsErrors.total_pages = 0;
      this._page_statsErrors.total_records = 0;
      this._minRecordDisplayErrors = 0;
      this._maxRecordDisplayErrors = 0;
    }
  }

  constructor() { }

  ngOnInit() {
  }

}
