import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import {Tag} from '../../model/KnowledgeOwl/Tag';
import {KnowledgeOwlHelperService} from '../knowledge-owl-helper.service';
import { environment } from 'src/environments/environment';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class KOTagsService {

  private _apiUrl = "";
  private tagsKO = {} as IDictionaryTags;
  private apiTO = environment.apiTimeOut;
  
  constructor(private _koHelperService : KnowledgeOwlHelperService,private http: Http) { 
    this._apiUrl = _koHelperService.getUrl();
    this.loadTags()
    .subscribe(tags=> this.processTags(tags));
  }

  /**
   * Returns the Tag indexed by id
   * @param id 
   */
   public getTag(id: string): Tag {
    return this.tagsKO[id];
  }

  /**
   * Process the json with all tags and populate the Tags dictionary 
   * @param tags 
   */
  private processTags(tags) {
    tags.forEach((c: any) => {
      this.tagsKO[c.id] = c
     });
    //  console.log("tagsKO ",this.tagsKO)
  }

  /**
   * Gets all KO tags
   */
  private loadTags() {
    let url = this._apiUrl + '/getTags';
    return this.http.get(url, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractData)
    .catch(this.handleError);
  }

  /**
   * Extract data from API response and convert it to json
   * @param res 
   */
  private extractData(res: Response) {
    if (res.text()) {
      const jsonResp = res.json();
      if (jsonResp.body.valid) {
        return jsonResp.body.data;
      } else {
        return {};
      }
    } else {
      return {};
    }
  }
;
  private handleError(error: any) {
    //console.log("Error " + error);
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Observable.throw(errMsg);
  }
}

interface IDictionaryTags {
  [index: string]: Tag;
}
