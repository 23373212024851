

export const LANG_GB_TRANS = {
    'Hello': 'Hello',
    'Select a topic': 'Select a topic',
    'How can we help you today': 'How can we help you today',
    'Start Chat': 'Start Chat',
    'What is your phone number': 'What is your phone number',
    'What is your email': 'What is your email',
    'Help': 'Help',
    'First name': 'First name',
    'Last name': 'Last name',
    'Record Locator': 'Record Locator',
    'Lniata': 'Lniata',
    'Chat is closed': 'Chat is closed',
    'Chat session has ended': 'Chat session has ended',
    'Thank you for contacting us': 'Thank you for contacting us',
    'Unable to start chat session': 'Unable to start chat session',
    'Formats': 'Formats',
    'Applications Support': 'Applications Support',
    'Sabre Red Workspace & Hardware': 'Sabre Red Workspace & Hardware',
    'TripCase and Virtual Payments': 'TripCase and Virtual Payments'
};