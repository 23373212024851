import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ErrorArticle } from 'src/app/model/FormatDB/ErrorArticle';

@Injectable({
  providedIn: 'root'
})
export class SabreRedMessagesService {
  private articleSearchError = new BehaviorSubject(new ErrorArticle);
  currentArticleSearchError = this.articleSearchError.asObservable();

  private textSearchFormat = new BehaviorSubject('');
  currentTextSearchFormat = this.textSearchFormat.asObservable();

  public changeArticleSearchError(errorArticle: ErrorArticle) {

    console.log("changeArticleSearchError", errorArticle)
    if (errorArticle) {
      this.articleSearchError.next(errorArticle)
    } else {
      //console.error("Article not found!")
    }
  }

  public changeTextSearchFormat(message: string) {
    this.textSearchFormat.next(message)
  }
  constructor() { }
}
