/**
 *  User
 */
export class User{
    
    /**
     * First Name
     */
    public FirstName : string;
    
    /**
     * Last Name
     */
    public LastName : string;

    /**
     * Email
     */
    public Email : string;

    /**
     * Pseudo City Code
     */
    public PCC : string;

    /**
     * EPR Number
     */
    public EPR : number;

    /**
     * User Language
     */
    public Language : string;

    /**
     * User Token
     */
    public Token : string;
}