

export const LANG_BR_TRANS = {
  'English': 'Inglês',
  'Spanish': 'Espanhol',
  'German': 'Alemão',
  'French': 'Francês',
  'Italian': 'Italiano',
  'Portuguese': 'Português',
  'Your token is not valid. Please try to login again' : 'Seu token não é válido. Por favor, tente fazer o login novamente' ,
  'ARTICLES_BTN' : 'Artigos' ,
  'FORMATS_BTN' : 'Comandos' ,
  'NOTES_BTN' : 'Notas' ,
  'BOOKMARKS_BTN' : 'Favoritos' ,
  'Search' : 'Pesquisar' ,
  'What do you want to search?' : 'O que você deseja pesquisar?' ,
  'Searching' : 'Pesquisa Rápida' ,
  'Article Search' : 'Pesquisar Artigo' ,
  'Format Search' : 'Pesquisar Formato' ,
  'Description': 'Descrição' ,
  'Tags': 'Tags' ,
  'STARTS_WITH_BTN' : 'Começa com' ,
  'ENDS_WITH_BTN' : 'Termina com' ,
  'CONTAINS_BTN' : 'Contém' ,
  "ADD_STAR_TIP" : 'Adicionar aos Favoritos' ,
  "ADD_NOTE_TIP" : 'Adicionar uma Anotação' ,
  "View More" : 'Ver Mais' ,
  "View Less" : 'Ver Menos' ,
  'Formats' : 'Formatos' ,
  'Commands' : 'Comandos' ,
  'Errors' : 'Erros' ,
  'Favorites' : 'Favoritos' ,
  'Loading': 'Carregando' ,
  'Use all CAPITALS for commands (or part of the command), and lowercase for descriptions' : 'Utilize MAIÚSCULAS para formatos (ou parte do formato) e minúsculas para descrições' ,
  'Availability' : 'Disponibilidade' ,
  'Paste a copy of the system error and/or error number to search' : 'Cole uma cópia do erro do sistema e/ou número de erro para pesquisar' ,
  'Search Examples:' : 'Exemplos de pesquisa:' ,
  'COMMAND + description:' : 'FORMATO + descrição:' ,
  ' WP with air extras' : ' WP com air extras' ,
  'Partial command:' : 'Formato parcial:' ,
  ' pricing with air extras' : ' tarifamento com air extras' ,
  'Example' : 'Examplo' ,
  'results' : 'resultados' ,
  'of' : 'de' ,
  'sec' : 'seg' ,
  'First' : 'Primeiro' ,
  'Last' : 'Último' ,
  'Previous' : 'Anterior' ,
  'Next' : 'Próximo' ,
  'Need more information or support?' : 'Precisa de mais informações ou suporte?' ,
  'Launch Finder' : 'Iniciar Finder',
  'Delete' : 'Remover' ,
  'Save' : 'Salvar' ,
  'Sabre Central Quick - Feedback' : 'Sabre Central Quick - Feedback' ,
  'If you would like to provide feedback on Quick Look, choose one of the features from the drop-down and provide your comments below.' : 'Se você gostaria de fornecer seu feedback sobre o Quick Look, escolha um dos recursos da lista suspensa e insira seus comentários abaixo.' ,
  'Retrieving Sabre Red Workspace Information' : 'Recuperando as informações do Sabre Red Workspace' ,
  'Retrieving User Information' : 'Recuperando Informações do Usuário' ,
  'Authenticating..' : 'Autenticando..' ,
  'Almost there..' : 'Estamos Quase Lá..' ,
  'Complete!' : 'Completo!' ,
  'Settings/Preferences' : 'Configurações/Preferências' ,
  'Error Listener Notification' : 'Notificação Error Listener' ,
  'Feedback Form' : 'Formulário de Feedback' ,
  'Language' : 'Idioma' ,
  'Feature' : 'Recurso' ,
  'Bookmarks' : 'Favoritos' ,
  'Notes' : 'Notas' ,
  'Enter your comments here' : 'Insira seus comentários aqui' ,
  'Send' : 'Enviar' ,
  'Comments' : 'Comentários' ,
  'Cancel' : 'Cancelar' ,
  'Feedback' : 'Feedback'
};
