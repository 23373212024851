
export const LANG_ZA_TRANS = {
    'Hello': 'Hola',
    'Select a topic': 'Selecciona un topico',
    'How can we help you today': 'Como podemos ayudarte',
    'Start Chat': 'Iniciar Chat',
    'What is your phone number': 'Cual es tu numero telefonico',
    'What is your email': 'Cual es tu email',
    'Help': 'Ayuda',
    'First name': 'Nombre',
    'Last name': 'Apellido',
    'Record Locator': 'Localizador',
    'Lniata': 'TA',
    'Chat is closed': 'Soporte no disponible',
    'Chat session has ended': 'Chat finalizado',
    'Thank you for contacting us': 'Gracias por contactarse',
    'Unable to start chat session': 'No se puedo iniciar la sesion de chat',
    'Formats': 'Formatos',
    'Applications Support': 'Soporte de Aplicaciones',
    'Sabre Red Workspace & Hardware': 'Sabre Red Workspace y Hardware',
    'TripCase and Virtual Payments': 'TripCase y Virtual Payments'
};